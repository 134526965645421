import DatePickerInput from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { useRef } from 'react';

const InputDate = ({
  title,
  minDate,
  maxDate,
  control,
  name,
  errors,
  ...props
}) => {
  const datepickerEl = useRef();
  return (
    <div className="flex flex-col mb-[18px]">
      <label className="text-sm">{title}</label>
      <div className="relative">
        <div className="customDatePicker">
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <div>
                <DatePickerInput
                  placeholderText="Select date"
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  dateFormat="MM/dd/yyyy"
                  isClearable
                  minDate={minDate ? minDate : null}
                  maxDate={maxDate ? maxDate : null}
                  autoComplete="off"
                  ref={datepickerEl}
                  className={clsx(
                    'w-full bg-white text-sm block p-[8px] border border-black-100 rounded-lg outline-0'
                  )}
                  {...props}
                />
                {!field.value ? (
                  <span
                    className="absolute right-[8px] top-[8px]"
                    onClick={() => datepickerEl.current.setFocus(true)}
                  >
                    <ion-icon
                      name="calendar-outline"
                      style={{ width: '16px', height: '16px' }}
                    ></ion-icon>
                  </span>
                ) : null}
              </div>
            )}
          />
        </div>
      </div>
      {errors && errors[name] && (
        <p className="text-xs text-red">{errors[name].message}</p>
      )}
    </div>
  );
};

export default InputDate;
