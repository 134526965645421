import { TableContainer, tableContainerClasses, styled } from '@mui/material';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [`&.${tableContainerClasses.root}`]: {
    transform: 'rotateX(180deg)',
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
      '&::-webkit-scrollbar': {
        height: '4px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#E1E1E1',
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#454545',
        borderRadius: '6px',
      },
    },
  },
}));

export default StyledTableContainer;
