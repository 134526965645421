import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setPrizeCurrency, thousandSeparator } from '../../utils/math';
import { currentTimezone } from '../../utils/date';
import ActionButton from '../Button/ActionButton';

const SeasonCard = ({ season, isHome }) => {
  const { usdPrice } = useSelector((state) => state.common);
  const { seasonFilter } = useSelector((state) => state.season);
  const navigate = useNavigate();

  return (
    <div
      key={season.lottery_season._id}
      className={`relative z-10 flex flex-col p-[16px] mb-[16px] border border-black-100 text-white items-center justify-center shadow-thick sm:w-[32%] sm:mb-[52px] ${
        season.status === 'Finished' ? 'bg-blue' : 'bg-red'
      } ${isHome && 'w-[95%] sm:min-w-[328px] mx-auto sm:mx-0'}`}
    >
      <div
        className={`w-full h-[151px] rounded-lg ${
          season.banner && season.banner.image
            ? 'bg-transparent'
            : 'bg-grey-banner'
        }`}
      >
        {season.banner && season.banner.image ? (
          <img
            src={season.banner.image}
            alt={`${season.lottery_season.name} banner`}
            className="w-full h-full object-cover rounded-lg"
          />
        ) : null}
      </div>
      <div className={`w-full flex flex-col items-center justify-center `}>
        <div
          className={`w-full border-b border-b-white text-center my-[4px] pb-[4px] `}
        >
          <h3 className="text-[24px] font-poppins-semibold">
            {setPrizeCurrency(season)}{' '}
            {thousandSeparator(season.active_drawdown.winning_pot_budget)}
          </h3>
        </div>
        <p className={`font-poppins-semibold text-lg uppercase my-[4px]`}>
          {season.lottery_season.name}
        </p>
        <p className="text-sm font-poppins-semibold">
          Draw Date:{' '}
          {season.active_drawdown && season.active_drawdown.draw_date
            ? moment(season.active_drawdown.draw_date)
                .tz(currentTimezone())
                .format('DD-MM-YYYY, HH:mm')
            : '-'}
        </p>
        <ActionButton
          type="button"
          onClick={() =>
            navigate(
              `/play/${season.lottery_season.id}?section=${
                seasonFilter.includes('Winners') ? 'winner' : ''
              }`
            )
          }
          iconName={season.status === 'Finished' && 'mic-circle'}
          iconSize="20"
          iconSrc={season.status !== 'Finished' && '8-ball'}
          iconPos="left"
          title={
            season.status === 'Finished'
              ? 'WATCH NOW!'
              : season.active_drawdown.status === 'Finished'
              ? 'CHECK NOW!'
              : 'PLAY NOW!'
          }
          classes={`mt-[4px]`}
        />
      </div>
    </div>
  );
};

export default SeasonCard;
