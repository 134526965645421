import { enqueueSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';

export function useCopyToClipboard() {
  const copyText = (text, successMessage) => {
    try {
      copy(text);
      enqueueSnackbar({ message: successMessage, variant: 'success' });
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      return false;
    }
  };

  return { copyText };
}
