import { useSelector } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CoinButton from '../Button/CoinButton';
import { useSearchParams } from 'react-router-dom';
import { useIsLoggedIn } from '../../hooks/useAuth';
import { useEffect, useState } from 'react';

const SeasonFilter = () => {
  const { seasonFilter } = useSelector((state) => state.season);
  const isLoggedIn = useIsLoggedIn();
  const { width } = useWindowDimensions();
  const [, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState([
    'All Seasons',
    'New Season',
    'Winners Announcement',
    'Highest Prize',
    'Popular',
    'My Seasons',
  ]);

  useEffect(() => {
    if (!isLoggedIn) {
      const newFilter = filters.slice(0, -1);
      setFilters(newFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div className="w-full py-[10px] flex flex-row justify-between px-[16px] sm:px-[48px] max-w-none overflow-auto bg-light-yellow border border-black-100">
      <div className="flex flex-row">
        {filters.map((filter, idx) => (
          <button
            key={`filter-${idx}`}
            type="button"
            className={`p-[8px] pr-[12px] border-r border-r-black-100 font-poppins-semibold text-sm min-w-fit ${
              seasonFilter === filter && 'text-blue'
            }`}
            onClick={() => setSearchParams({ season_filter: filter })}
          >
            {filter}
          </button>
        ))}
      </div>
      {width > 640 ? <CoinButton /> : null}
    </div>
  );
};

export default SeasonFilter;
