import Modal from '../Modal/Modal';

const ActiveWalletInfo = ({ closeModal }) => {
  return (
    <Modal closeModal={closeModal} title="Information">
      <ul className="list-[lower-decimal] ml-[20px] text-sm">
        <li>
          Please input your assigned external wallet when transferring funds to
          your luckycat88 account. This will help us differentiate your account
          from others.
        </li>
        <li>
          Blockchain we use is{' '}
          <span className="font-poppins-semibold">ERC20</span>.
        </li>
      </ul>
    </Modal>
  );
};

export default ActiveWalletInfo;
