import { createSlice } from '@reduxjs/toolkit';
import {
  fetchPlayerFriendsList,
  fetchPlayerTicketHistory,
  fetchPlayerTransactionDetail,
  fetchPlayerTransactionHistory,
} from '../actions/player';

const initialState = {
  profileSections: [
    {
      name: 'History',
      icon: 'ticket',
      path: '/history',
    },
    {
      name: 'Wallet Information',
      icon: 'wallet',
      modal: 'wallet',
    },
    {
      name: 'Refer Friend List',
      icon: 'link',
      modal: 'refer_friend',
    },
    {
      name: 'Exchange',
      icon: 'swap-horizontal',
      modal: 'exchange_wallet',
    },
    {
      name: 'Bio & Referral Code',
      icon: 'person-circle',
      path: '/profile/bio',
    },
    {
      name: 'Logout',
      icon: 'log-out-outline',
      modal: 'logout',
    },
  ],
  isLoading: false,
  transactionHistory: [],
  ticketHistory: [],
  friendsList: [],
  trsPageData: {
    search: '',
    dataLimit: 10,
    page: 0,
  },
  ticketPageData: {
    search: '',
    dataLimit: 10,
    page: 0,
    ticket_status: 'All',
    startDate: null,
    endDate: null,
  },
  friendsPageData: {
    search: '',
    dataLimit: 10,
    page: 0,
  },
  trsTotalPages: 0,
  ticketTotalPages: 0,
  friendsTotalPages: 0,
  invoice: null,
};

export const player = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setTrsPageData: (state, action) => {
      state.trsPageData = { ...state.trsPageData, ...action.payload };
    },
    setFriendsPageData: (state, action) => {
      state.friendsPageData = { ...state.friendsPageData, ...action.payload };
    },
    setTicketPageData: (state, action) => {
      state.ticketPageData = { ...state.ticketPageData, ...action.payload };
    },
  },
  extraReducers: {
    [fetchPlayerTransactionHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerTransactionHistory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.transactionHistory = payload.transaction_list;
      state.trsTotalPages = payload.pages;
    },
    [fetchPlayerTransactionHistory.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchPlayerTicketHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerTicketHistory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.ticketHistory = payload.ticket_list;
      state.ticketTotalPages = payload.pages;
    },
    [fetchPlayerTicketHistory.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchPlayerTransactionDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerTransactionDetail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { transaction } = payload;
      const ticketList = transaction.ticket_list.reduce((acc, obj) => {
        acc[obj.drawdown.id] = acc[obj.drawdown.id] || [];
        acc[obj.drawdown.id].push(obj);
        return acc;
      }, {});

      const transformedTicketList = Object.entries(ticketList).map(
        ([key, value]) => ({ [key]: value })
      );
      // console.log(transformedTicketList, '====ticket-list');
      transaction.ticket_list = transformedTicketList;
      state.invoice = payload.transaction;
    },
    [fetchPlayerTransactionDetail.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchPlayerFriendsList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerFriendsList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.friendsList = payload.friends_list;
      state.friendsTotalPages = payload.pages;
    },
    [fetchPlayerFriendsList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTicketPageData, setTrsPageData, setFriendsPageData } =
  player.actions;

export default player.reducer;
