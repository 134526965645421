import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { fetchSeasonList } from '../actions/season';
import Loading from '../components/Loading';
import { useSearchParams } from 'react-router-dom';
import SeasonFilter from '../components/Season/SeasonFilter';
import LayoutUser from '../components/Layout/LayoutUser';
import SeasonBg from '../assets/imgs/season-list-bg.png';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { setSeasonFilter } from '../slices/seasonSlice';
import HighestPrizeSeasonCard from '../components/Season/HighestPrizeSeasonCard';
import SeasonCard from '../components/Season/SeasonCard';

const SeasonListPage = () => {
  const { isLoading, seasonList, seasonFilter } = useSelector(
    (state) => state.season
  );
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
    const filter = searchParams.get('season_filter');
    if (filter) {
      dispatch(setSeasonFilter(filter));
    } else {
      dispatch(setSeasonFilter('New Season'));
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (seasonFilter) {
      dispatch(fetchSeasonList({ season_filter: seasonFilter }));
    }
  }, [dispatch, seasonFilter]);

  return (
    <LayoutUser showTabMenu={true} showDetailNav={width < 640} backTo="/">
      {isLoading ? <Loading /> : null}
      <SeasonFilter />
      <section
        className={`bg-yellow min-h-[50vh] flex flex-col p-[16px] pb-[64px] sm:flex-wrap sm:flex-row sm:gap-x-[16px] sm:py-[40px] sm:px-[5%] relative ${
          seasonFilter === 'Highest Prize' && 'justify-center !bg-[#0E90AD]'
        }`}
      >
        <img
          src={SeasonBg}
          alt="season-bg"
          className="w-full h-full object-cover opacity-5 absolute top-0 left-0"
        />
        {seasonList.length ? (
          <>
            {seasonList.map((season) => (
              <React.Fragment key={season._id}>
                {seasonFilter === 'Highest Prize' ? (
                  <HighestPrizeSeasonCard season={season} />
                ) : (
                  <SeasonCard season={season} />
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <p className="text-center font-poppins-semibold">No season found!</p>
        )}
      </section>
    </LayoutUser>
  );
};

export default SeasonListPage;
