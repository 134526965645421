import { useForm } from 'react-hook-form';
import Modal from './Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPassEmail } from '../../utils/validationForm';
import Input from '../Input/Input';
import ActionButton from '../Button/ActionButton';
import { useDispatch } from 'react-redux';
import { forgotPasswordStep1 } from '../../actions/auth';
import { setForgotPassPayload } from '../../slices/authSlice';
import getToastMessage from '../../utils/toastMessage';

const ForgotPasswordEmail = ({ closeModal }) => {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(forgotPassEmail),
  });
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const done = await dispatch(forgotPasswordStep1(data));
    if (done) {
      const { variant } = getToastMessage(done);
      if (variant === 'success') {
        dispatch(setForgotPassPayload(data));
        closeModal('forgot_pass_otp');
      }
    }
  };

  return (
    <Modal closeModal={closeModal} title="Forgot Password">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          errors={formState.errors}
          title="Input your email"
          name="email"
          placeholder="yourmail@mail.com"
        />
        <ActionButton type="submit" title="Send OTP" />
      </form>
    </Modal>
  );
};

export default ForgotPasswordEmail;
