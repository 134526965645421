import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import LoginPage from './pages/Login';
import HomePage from './pages/Home';
import RegisterPage from './pages/Register';
import SeasonListPage from './pages/SeasonList';
import SeasonDetailPage from './pages/SeasonDetail';
import ProfilePage from './pages/Profile';
import HistoryPage from './pages/History';
import ProfileBioPage from './pages/ProfileBio';
import FAQPage from './pages/FAQ';
import AboutPage from './pages/About';
import PrivateRoutes from './components/Route/PrivateRoutes';
import ContactPage from './pages/Contact';
import InvoicePage from './pages/Invoice';
import { useEffect } from 'react';
import { getUSDPrice } from './actions/common';
import { jakartaCurrentTime } from './utils/date';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  useEffect(() => {
    store.dispatch(getUSDPrice()).then((res) => {
      const { payload } = res;
      if (payload.current_date && payload.duration) {
        const date = new Date(payload.current_date);
        jakartaCurrentTime(
          payload.duration >= 1
            ? date.setSeconds(date.getSeconds() + Math.floor(payload.duration))
            : date
        );
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<RegisterPage />} />
        <Route path="/signup/:id" element={<RegisterPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/play" element={<SeasonListPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/invoice/:id" element={<InvoicePage />} />
          <Route path="/play/:id" element={<SeasonDetailPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/history" element={<HistoryPage />} />
          <Route path="/profile/bio" element={<ProfileBioPage />} />
        </Route>
        <Route path="*" title="ERROR 404" element={<NotFoundPage />} />
      </Routes>
    </Provider>
  );
}

export default App;
