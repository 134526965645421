import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import moment from 'moment-timezone';
import { currentTimezone } from '../../utils/date';
import { setSelectedAnnouncement } from '../../slices/seasonSlice';
import ReactPlayer from 'react-player';

const VideoAnnoucement = ({ isSeasonDetail }) => {
  const { selectedDrawdownInfo, selectedSeasonInfo, selectedAnnouncement } =
    useSelector((state) => state.season);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  return (
    <>
      {selectedDrawdownInfo ? (
        <div
          className={`flex flex-col ${
            isSeasonDetail && 'border-y border-y-[#E0E0E0] py-[16px]'
          } gap-[8px]`}
        >
          {selectedAnnouncement ? (
            <div>
              <ReactPlayer
                url={selectedAnnouncement.video_link}
                width="100%"
                height={width > 640 ? 250 : 200}
                style={{ boxShadow: '4px 4px 0px 0px #000' }}
              />
            </div>
          ) : null}
          {selectedDrawdownInfo.draw_date &&
          (selectedDrawdownInfo.status === 'Active' ||
            selectedDrawdownInfo.status === 'Finished') ? (
            <p className="text-sm font-poppins-semibold pl-[16px] sm:p-0">
              Live draw down on{' '}
              {moment(selectedDrawdownInfo.draw_date)
                .tz(currentTimezone())
                .format('MMMM Do YYYY, HH:mm')}{' '}
              (
              {moment(selectedDrawdownInfo.draw_date)
                .tz(currentTimezone())
                .fromNow()}
              )
            </p>
          ) : selectedDrawdownInfo.status === 'Inactive' &&
            selectedSeasonInfo.status === 'Finished' ? (
            <p className="text-sm font-poppins-semibold mt-[20px] pl-[16px] sm:p-0">
              This season is Finished! Please refer to
              {selectedSeasonInfo.active_drawdown.drawdown.name} as the latest
              drawdown
            </p>
          ) : (
            <p className="text-sm font-poppins-semibold mt-[20px] pl-[16px] sm:p-0">
              Secure your spot now for "
              {selectedSeasonInfo.active_drawdown.drawdown.name}" draw down -
              grab your active ticket today!
            </p>
          )}

          {selectedDrawdownInfo.announcement_list.length ? (
            <div
              className={`flex flex-row mb-[16px] gap-[16px] max-w-none overflow-auto mt-[8px] ${
                isSeasonDetail && '!mb-0'
              }`}
            >
              {selectedDrawdownInfo.announcement_list.map((announ, idx) => (
                <button
                  key={`${announ.video_link}-${idx}`}
                  onClick={() => dispatch(setSelectedAnnouncement(announ))}
                  className={`first:ml-[16px] first:sm:ml-0 last:mr-[16px] bg-white min-w-fit h-fit text-black-100 px-[16px] py-[8px] border border-black-100 font-poppins-semibold ${
                    selectedAnnouncement &&
                    selectedAnnouncement.video_link === announ.video_link &&
                    '!bg-black-100 text-white'
                  }`}
                >
                  {announ.video_title}
                </button>
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default VideoAnnoucement;
