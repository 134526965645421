import { useEffect, useState } from 'react';
import Modal from './Modal';
import ActionButton from '../Button/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  generateNumberRange,
  getCombinationsCount,
  getTicketCombinations,
  removePurchasedTickets,
  thousandSeparator,
} from '../../utils/math';
import { setSelectedTickets } from '../../slices/seasonSlice';
import { checkTicketNumbers } from '../../actions/season';
import getToastMessage from '../../utils/toastMessage';
import CoinLogo from '../../assets/imgs/coin.png';
import SelectMultipleDrawdown from '../Season/SelectMultipleDrawdown';

const BuyTicket = ({ closeModal }) => {
  const [ticketList, setTicketList] = useState([]);
  const {
    selectedSeasonInfo,
    selectedTickets,
    playerActiveTicketList,
    selectedDrawdownPurchase,
  } = useSelector((state) => state.season);
  const dispatch = useDispatch();

  const purchase = async () => {
    if (ticketList.length >= 6 && selectedDrawdownPurchase.length) {
      const payload = {
        lottery_season_id: selectedSeasonInfo.lottery_season.id,
        drawdown_id_list: selectedDrawdownPurchase.map((d) => d.drawdown.id),
        ticket_number_list: ticketList,
      };
      const done = await dispatch(checkTicketNumbers(payload));
      if (done) {
        const { variant } = getToastMessage(done);
        if (variant === 'success') {
          dispatch(setSelectedTickets(ticketList));
          closeModal(false);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedTickets.length) setTicketList(selectedTickets);
  }, [selectedTickets]);

  return (
    <Modal title="Add Ticket" closeModal={closeModal}>
      <div className="flex flex-col">
        <SelectMultipleDrawdown />
        <div className="flex flex-row text-sm items-center justify-between mb-[10px]">
          <p>Select Your Numbers</p>
          <p className="font-poppins-semibold"># {ticketList.length}</p>
        </div>
        <div className="flex flex-row gap-[8px] pb-[16px] max-w-none overflow-auto">
          {ticketList.length
            ? ticketList.map((ticket, idx) => (
                <button
                  key={`ticketnum-${idx}`}
                  className="flex justify-center items-center min-w-[26px] h-[26px] rounded-full text-center font-poppins-bold bg-black-100 text-white border border-black-100 text-sm"
                  type="button"
                  onClick={() =>
                    setTicketList((prevState) =>
                      prevState.filter((number) => number !== ticket)
                    )
                  }
                >
                  {ticket}
                </button>
              ))
            : null}
        </div>
      </div>
      <div className="flex flex-col mb-[16px]">
        <p className="mb-[12px] text-sm">Available Numbers</p>
        <div className="flex flex-wrap gap-[8px]">
          {generateNumberRange(
            selectedSeasonInfo.min_number,
            selectedSeasonInfo.max_number
          ).map((num, idx) => (
            <button
              key={`availnum-${idx}`}
              className={`text-sm flex justify-center items-center font-poppins-bold w-[26px] h-[26px] rounded-full border border-black-100 text-center ${
                ticketList.includes(num)
                  ? 'bg-black-100 text-white'
                  : 'bg-white text-black-100'
              }`}
              onClick={() => {
                if (ticketList.includes(num)) {
                  setTicketList((prevState) =>
                    prevState.filter((number) => number !== num)
                  );
                } else {
                  setTicketList((prevState) => [...prevState, num]);
                }
              }}
              type="button"
            >
              {num}
            </button>
          ))}
        </div>
      </div>
      <div className="flex flex-col mb-[16px]">
        <h3 className="text-base font-poppins-semibold">Total</h3>
        <span className="flex flex-row items-center">
          <img src={CoinLogo} alt="coin-logo" className="w-[26px] h-[26px]" />
          <p className="font-poppins-semibold ml-[6px]">
            {thousandSeparator(
              playerActiveTicketList.length
                ? removePurchasedTickets(
                    getTicketCombinations(ticketList),
                    playerActiveTicketList,
                    selectedDrawdownPurchase.map((d) => d.drawdown.id)
                  ) * selectedSeasonInfo.ticket_price
                : getCombinationsCount(ticketList) *
                    selectedSeasonInfo.ticket_price *
                    selectedDrawdownPurchase.length
            )}
          </p>
        </span>
      </div>
      <div className="flex flex-col items-center justify-center pb-[4px]">
        <ActionButton
          title="Apply"
          classes={`${
            (ticketList.length < 6 || !selectedDrawdownPurchase.length) &&
            'opacity-50 cursor-not-allowed'
          }`}
          onClick={purchase}
        />
        {ticketList.length < 6 ? (
          <p className="text-sm text-red mt-[5px]">
            Select minimum 6 numbers to proceed.
          </p>
        ) : null}
      </div>
    </Modal>
  );
};

export default BuyTicket;
