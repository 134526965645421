import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SecondaryButton from '../components/Button/SecondaryButton';
import { addReferrer, forgotPasswordStep1 } from '../actions/auth';
import Loading from '../components/Loading';
import ActionButton from '../components/Button/ActionButton';
import getToastMessage from '../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import ForgotPasswordOTP from '../components/Modal/ForgotPasswordOTP';
import ChangePassword from '../components/Modal/ChangePassword';
import { setForgotPassPayload } from '../slices/authSlice';
import LayoutUser from '../components/Layout/LayoutUser';
import ReferFriends from '../components/Modal/ReferFriends';

const ProfileBioPage = () => {
  const { userInfo, isLoading } = useSelector((state) => state.auth);
  const referralInput = useRef();
  const dispatch = useDispatch();
  const [modalSection, setModalSection] = useState('');

  const onAddReferral = () => {
    const referralCode = referralInput.current.value;
    if (referralCode) {
      const done = dispatch(addReferrer({ referral_code: referralCode }));
      if (done) {
        const { message, variant } = getToastMessage(done);
        if (variant === 'success') enqueueSnackbar(message, { variant });
      }
    }
  };

  const sendForgotPassOTP = async () => {
    const done = await dispatch(forgotPasswordStep1({ email: userInfo.email }));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        dispatch(setForgotPassPayload({ email: userInfo.email }));
        setModalSection('forgot_pass_otp');
      }
    }
  };

  return (
    <LayoutUser showDetailNav={true} backTo="/profile">
      {isLoading ? <Loading /> : null}
      {modalSection === 'forgot_pass_otp' ? (
        <ForgotPasswordOTP closeModal={setModalSection} />
      ) : modalSection === 'change_password' ? (
        <ChangePassword closeModal={setModalSection} />
      ) : modalSection === 'refer' ? (
        <ReferFriends closeModal={setModalSection} />
      ) : null}
      <div className="flex flex-col w-full h-full sm:flex-row sm:justify-between">
        <div className="bg-blue w-full h-[166px] sm:w-[55%] sm:h-[100vh]"></div>
        <div className="flex flex-col justify-center items-center w-[80%] m-auto pb-[32px] sm:w-[35%]">
          <h2 className="text-lg font-poppins-semibold my-[32px]">Your Bio</h2>
          {userInfo ? (
            <div className="w-full">
              <div className="flex flex-col text-sm mb-[18px]">
                <label>Email</label>
                <p className="p-[8px]">{userInfo.email}</p>
              </div>
              <div className="flex flex-col text-sm mb-[18px]">
                <label>Birth Date</label>
                <p className="p-[8px]">
                  {moment(userInfo.date_of_birth).format('DD-MM-YYYY')}
                </p>
              </div>
              <div className="flex flex-col text-sm mb-[18px]">
                <label>Password</label>
                <div className="flex flex-row justify-between items-center">
                  <input
                    type="password"
                    className="border-0 outline-none p-[8px] text-xl"
                    value="123456"
                    disabled
                  />
                  <button
                    className="flex"
                    type="button"
                    onClick={sendForgotPassOTP}
                  >
                    <ion-icon
                      name="create-outline"
                      style={{ width: '24px', height: '24px' }}
                    ></ion-icon>
                  </button>
                </div>
              </div>
              <div className="flex flex-col text-sm mb-[18px]">
                <label>Referral Code</label>
                <div className="flex flex-row items-center">
                  {!userInfo.referrer_info ? (
                    <>
                      <input
                        type="text"
                        placeholder="Code"
                        ref={referralInput}
                        className="border border-black-100 rounded-lg outline-none p-[8px] bg-white w-[70%]"
                      />
                      <SecondaryButton
                        title="Add"
                        classes="!w-fit !text-sm ml-[14px]"
                        onClick={onAddReferral}
                      />
                    </>
                  ) : (
                    <div className="mt-[8px] flex flex-row items-center">
                      <ion-icon
                        name="checkmark-circle"
                        style={{
                          color: '#5ABA47',
                          width: '16px',
                          height: '16px',
                        }}
                      ></ion-icon>
                      <p className="ml-[4px]">
                        you are using code from{' '}
                        <span className="opacity-30">
                          {userInfo.referrer_info.referrer_id}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <ActionButton
                title="Refer Friends"
                classes="mt-[8px]"
                onClick={() => setModalSection('refer')}
              />
            </div>
          ) : null}
        </div>
      </div>
    </LayoutUser>
  );
};

export default ProfileBioPage;
