import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactPlatform } from '../../actions/common';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/imgs/footer-logo.png';
import FooterBg from '../../assets/imgs/footer-bg.png';
import { useIsLoggedIn } from '../../hooks/useAuth';

const Footer = () => {
  const { contactPlatformList, fetchedContact } = useSelector(
    (state) => state.common
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  const [links, setLinks] = useState([
    { name: 'Home', path: '/' },
    { name: 'Play', path: '/play' },
    { name: 'About Us', path: '/about' },
    { name: 'FAQ', path: '/faq' },
    { name: 'Contact Us', path: '/contact' },
    { name: 'Sign in/up', path: '/login' },
  ]);
  const contents = [
    'Service & Terms',
    'Privacy Policy',
    'Responsible Gambling',
  ];

  useEffect(() => {
    if (!fetchedContact) {
      dispatch(fetchContactPlatform());
    }
  }, [dispatch, fetchedContact]);

  useEffect(() => {
    if (isLoggedIn) {
      const loggedInLinks = links.filter((link) => !link.name.includes('Sign'));
      setLinks(loggedInLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <section className="w-full">
      <div className="flex flex-col sm:flex-row sm:justify-between sm:px-[75px] py-[64px] px-[16px] bg-[#000000] relative">
        <img
          src={FooterBg}
          alt="footer-bg"
          className="w-full h-full object-cover opacity-20 absolute top-0 left-0"
        />
        <div className="flex flex-col sm:w-1/2 sm:border-b-0 sm:border-r sm:border-r-white gap-[20px] border-b border-b-white pb-[16px] relative z-2">
          <div className="w-[206px] h-[66px">
            <img
              src={Logo}
              alt="footer logo"
              className="w-full h-full object-contain"
            />
          </div>
          <p className="text-lg text-white">Join our Community</p>
          {contactPlatformList && contactPlatformList.length ? (
            <div className="flex flex-row gap-[20px] items-center">
              {contactPlatformList.map((contact) => (
                <button
                  className="flex h-[24px] w-[24px]"
                  type="button"
                  key={contact._id}
                  onClick={() =>
                    window.open(contact.link, '_blank', 'noopener,noreferrer')
                  }
                >
                  <img
                    src={contact.icon}
                    alt={contact.name}
                    className="w-full h-full object-contain"
                  />
                </button>
              ))}
            </div>
          ) : null}
        </div>
        <div className="flex flex-col sm:flex-row sm:w-[45%] sm:py-[12px] relative z-2">
          <div className="flex flex-col gap-[20px] sm:mr-[10%] sm:my-0 mt-[20px] mx-[12px]">
            {links.map((link) => (
              <p
                className="text-lg text-white cursor-pointer"
                key={link.name}
                onClick={() => {
                  if (link.path) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    navigate(link.path);
                  }
                }}
              >
                {link.name}
              </p>
            ))}
          </div>
          <div className="flex flex-col gap-[20px] sm:my-0 mt-[20px] mx-[12px]">
            {contents.map((content, idx) => (
              <p
                className="text-lg text-white cursor-pointer"
                key={`${content}-${idx}`}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  navigate(encodeURI(`/about?content_section=${content}`));
                }}
              >
                {content}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-red text-center pt-[18px] pb-[95px] sm:pb-[18px]">
        <p className="text-white text-lg">Copyright © 2024 LuckyCat88.com</p>
      </div>
    </section>
  );
};

export default Footer;
