import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TrsHistory from '../components/History/TrsHistory';
import TicketHistory from '../components/History/TicketHistory';
import LayoutUser from '../components/Layout/LayoutUser';

const HistoryPage = () => {
  const sections = ['Tickets', 'Transactions'];
  const [selectedSection, setSelectedSection] = useState('Tickets');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const historySection = searchParams.get('section');
    if (historySection) setSelectedSection(historySection);
  }, [searchParams]);

  return (
    <LayoutUser showDetailNav={true} backTo="/profile" showTabMenu={true}>
      <main className="py-[24px] px-[16px] sm:w-[90%] m-auto flex flex-col">
        <h3 className="text-lg font-poppins-semibold mb-[16px]">History</h3>
        <div className="flex flex-row w-full border border-black-100 rounded-lg mb-[16px]">
          {sections.map((section, idx) => (
            <button
              type="button"
              className={`w-1/2 text-center text-lg font-poppins-semibold first:border-r first:border-r-black-100 p-[10px] ${
                selectedSection === section && 'bg-yellow'
              }`}
              onClick={() => setSearchParams({ section })}
              key={`${section}-${idx}`}
            >
              {section}
            </button>
          ))}
        </div>
        {selectedSection === 'Tickets' ? <TicketHistory /> : <TrsHistory />}
      </main>
    </LayoutUser>
  );
};

export default HistoryPage;
