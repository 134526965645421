import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIsLoggedIn } from '../../hooks/useAuth';
import { Box, Drawer } from '@mui/material';
import Logo from '../../assets/imgs/logo.png';
import LogoBlack from '../../assets/imgs/logo-black.png';
import { setIsMobileNavOpen, setSelectedMenu } from '../../slices/navbarSlice';
import ActionButton from '../Button/ActionButton';

const Navbar = ({ isHome = false, is404 = false }) => {
  const { selectedMenu, isMobileNavOpen, menus } = useSelector(
    (state) => state.navbar
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    const pathname = location.pathname;
    const selected = menus.filter((menu) => menu.path === pathname);
    if (selected.length) {
      dispatch(setSelectedMenu(selected[0].name));
    } else {
      dispatch(setSelectedMenu(''));
    }
  }, [dispatch, location.pathname, menus]);

  return (
    <>
      <div
        className={`flex flex-row sm:justify-between items-center w-full py-[10px] px-[16px] sm:px-[48px] ${
          isHome
            ? 'bg-transparent absolute top-0'
            : ' bg-blue border-b-2 border-b-black-100'
        } ${is404 && 'bg-yellow !border-0'}`}
      >
        <div
          className="h-[32px] w-[50%] sm:w-[20%] mr-auto sm:mr-0 cursor-pointer"
          onClick={() => navigate('/')}
        >
          <img
            src={is404 ? LogoBlack : Logo}
            className="w-full h-full object-contain"
            alt="lucky cat logo"
          />
        </div>
        <div
          className={`hidden sm:flex flex-row gap-[28px] ${
            is404 ? 'text-black-100' : 'text-white'
          }`}
        >
          {menus.map((menu) => (
            <button
              key={menu.name}
              type="button"
              onClick={() => {
                if (menu.path) navigate(menu.path);
              }}
              className={`text-lg font-poppins-semibold text-left ${
                selectedMenu === menu.name && 'underline'
              }`}
            >
              {menu.name}
            </button>
          ))}
          <ActionButton
            title={isLoggedIn ? `Profile` : 'SIGN IN/UP'}
            iconPos="left"
            iconSize="24"
            iconName="log-out-outline"
            classes="!w-fit hidden sm:flex"
            onClick={() => navigate(isLoggedIn ? '/profile' : '/login')}
          />
        </div>
        <button
          className="flex ml-auto sm:hidden"
          onClick={() => dispatch(setIsMobileNavOpen(!isMobileNavOpen))}
        >
          <ion-icon
            name="menu-outline"
            style={{
              width: '24px',
              height: '24px',
              color: is404 ? '#212121' : '#ffffff',
            }}
          ></ion-icon>
        </button>
      </div>
      <Drawer
        anchor="right"
        open={isMobileNavOpen}
        id="nav-drawer"
        PaperProps={{
          sx: {
            boxShadow: 'none',
          },
        }}
        ModalProps={{
          hideBackdrop: true,
        }}
        onClose={() => dispatch(setIsMobileNavOpen(false))}
      >
        <Box
          role="presentation"
          sx={{
            width: '100vw',
            backgroundColor: '#FFD600',
            height: '100vh',
            padding: '32px 16px',
          }}
          onClick={() => dispatch(setIsMobileNavOpen(false))}
          onKeyDown={() => dispatch(setIsMobileNavOpen(false))}
        >
          <div className="flex flex-col h-full relative items-center justify-center">
            <button type="button" className="absolute top-0 right-0">
              <ion-icon
                name="close-circle-outline"
                style={{
                  width: '37px',
                  height: '37px',
                }}
              ></ion-icon>
            </button>
            {menus.map((menu) => (
              <button
                key={menu.name}
                type="button"
                onClick={() => {
                  if (menu.path) navigate(menu.path);
                }}
                className={`mb-[28px] text-center text-2xl font-poppins-semibold ${
                  selectedMenu === menu.name && 'underline'
                }`}
              >
                {menu.name}
              </button>
            ))}
            <ActionButton
              title={isLoggedIn ? `Profile` : 'SIGN IN/UP'}
              classes="w-fit"
              iconPos="left"
              iconSize="24"
              iconName="log-out-outline"
              onClick={() => navigate(isLoggedIn ? '/profile' : '/login')}
            />
            <p className="text-sm absolute bottom-0 text-center w-full">
              Copyright © 2024 LuckyCay88 Software ltd.
            </p>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
