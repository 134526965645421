import { createSlice } from '@reduxjs/toolkit';
import {
  checkTicketNumbers,
  fetchDrawdownList,
  fetchDrawdownInfo,
  fetchSeasonInfo,
  fetchSeasonList,
  getPlayerTicketList,
  getPlayerTicketListByDrawdown,
  purchaseTicket,
} from '../actions/season';
import moment from 'moment-timezone';
import { CURRENT_TIME } from '../utils/date';

const initialState = {
  seasonList: [],
  drawdownList: [],
  selectedSeasonInfo: null,
  selectedDrawdownInfo: null,
  selectedAnnouncement: null,
  isLoading: false,
  seasonFilter: '',
  totalPages: 0,
  selectedTickets: [],
  selectedDrawdownPurchase: [],
  availableDrawdownList: [],
  tempGeneratedTickets: [],
  playerActiveTicketList: [],
  playerTicketList: [],
  playerWinningTickets: [],
  selectedSection: 'drawdown',
  sections: [
    {
      name: 'Play Next Draw Down',
      query: 'drawdown',
    },
    {
      name: 'Win Announcement',
      query: 'winner',
    },
    {
      name: 'How to Play',
      query: 'tutorial',
    },
  ],
  pageData: {
    page: 0,
    search: '',
    dataLimit: 10,
  },
};

export const season = createSlice({
  name: 'season',
  initialState,
  reducers: {
    setPageData: (state, action) => {
      state.pageData = { ...state.pageData, ...action.payload };
    },
    setSeasonFilter: (state, action) => {
      state.seasonFilter = action.payload;
    },
    setWinnerageData: (state, action) => {
      state.winnerPageData = { ...state.winnerPageData, ...action.payload };
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setSelectedTickets: (state, action) => {
      state.selectedTickets = action.payload;
    },
    setSelectedDrawdownInfo: (state, action) => {
      state.selectedDrawdownInfo = action.payload;
    },
    setSelectedAnnouncement: (state, action) => {
      state.selectedAnnouncement = action.payload;
    },
    setSelectedDrawdownPurchase: (state, action) => {
      state.selectedDrawdownPurchase = action.payload;
    },
    removeDrawdonwSection: (state, action) => {
      const sections = initialState.sections;
      state.sections = sections.slice(1, sections.length);
    },
    resetTickets: (state, action) => {
      state.tempGeneratedTickets = [];
      state.selectedTickets = [];
      state.drawdownList = [];
      state.selectedSeasonInfo = null;
      state.selectedAnnouncement = null;
      state.selectedDrawdownInfo = null;
    },
    resetSections: (state, action) => {
      state.sections = initialState.sections;
    },
  },
  extraReducers: {
    [fetchSeasonList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchSeasonList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.seasonList = payload.lottery_season_list;
      state.totalPages = payload.pages;
    },
    [fetchSeasonList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchSeasonInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchSeasonInfo.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedSeasonInfo = payload;
    },
    [fetchSeasonInfo.rejected]: (state) => {
      state.isLoading = false;
    },
    [fetchDrawdownList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchDrawdownList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.drawdownList = payload.drawdown_list;
      if (!state.selectedDrawdownInfo && payload.drawdown_list.length) {
        const currentActive = payload.drawdown_list.filter(
          (draw) => draw.status === 'Active'
        )[0];
        const currentFinished = payload.drawdown_list.filter(
          (draw) => draw.status === 'Finished'
        );

        const drawdown = currentActive
          ? currentActive
          : currentFinished && currentFinished.length
          ? currentFinished[currentFinished.length - 1]
          : payload.drawdown_list[0];

        const currentActiveIdx = payload.drawdown_list.findIndex(
          (d) => d._id === drawdown._id
        );
        if (currentActiveIdx >= 0) {
          let availableDrawdown = payload.drawdown_list.slice(
            currentActiveIdx,
            payload.drawdown_list.length
          );
          state.availableDrawdownList = availableDrawdown.filter(
            (d) =>
              !d.purchase_freeze_time ||
              (d.purchase_freeze_time &&
                moment(CURRENT_TIME).isBefore(d.purchase_freeze_time))
          );
        }

        state.selectedDrawdownInfo = drawdown;
        state.selectedDrawdownPurchase = state.availableDrawdownList.length
          ? [state.availableDrawdownList[0]]
          : [drawdown];
        state.selectedAnnouncement = drawdown.announcement_list.length
          ? drawdown.announcement_list[0]
          : null;
      }
    },
    [fetchDrawdownList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchDrawdownInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchDrawdownInfo.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedDrawdownInfo = payload.drawdown;
    },
    [fetchDrawdownInfo.rejected]: (state) => {
      state.isLoading = false;
    },
    [purchaseTicket.pending]: (state) => {
      state.isLoading = true;
    },
    [purchaseTicket.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.selectedTickets = [];
      state.tempGeneratedTickets = [];
    },
    [purchaseTicket.rejected]: (state) => {
      state.isLoading = false;
    },
    [checkTicketNumbers.pending]: (state) => {
      state.isLoading = true;
    },
    [checkTicketNumbers.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.tempGeneratedTickets = payload.total_ticket_list;
    },
    [checkTicketNumbers.rejected]: (state) => {
      state.isLoading = false;
    },
    [getPlayerTicketList.pending]: (state) => {
      state.isLoading = true;
    },
    [getPlayerTicketList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.playerActiveTicketList = payload.ticket_list;
    },
    [getPlayerTicketList.rejected]: (state) => {
      state.isLoading = false;
    },
    [getPlayerTicketListByDrawdown.pending]: (state) => {
      state.isLoading = true;
    },
    [getPlayerTicketListByDrawdown.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.playerTicketList = payload.ticket_list;
      if (payload.ticket_list.length) {
        state.playerWinningTickets = payload.ticket_list.filter((ticket) =>
          ticket.ticket_status.includes('Win')
        );
      } else {
        state.playerWinningTickets = [];
      }
    },
    [getPlayerTicketListByDrawdown.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  setPageData,
  setWinnerageData,
  setSelectedSection,
  setSelectedTickets,
  resetTickets,
  setSeasonFilter,
  setSelectedDrawdownInfo,
  setSelectedAnnouncement,
  setSelectedDrawdownPurchase,
  resetSections,
  removeDrawdonwSection,
} = season.actions;

export default season.reducer;
