import DetailNavbar from '../Navbar/DetailNavbar';
import Footer from './Footer';
import Navbar from '../Navbar/Navbar';
import TabMenu from '../Navbar/TabMenu';

const LayoutUser = ({
  showDetailNav = false,
  showTabMenu = false,
  isHome = false,
  is404 = false,
  backTo,
  children,
}) => {
  return (
    <>
      {showDetailNav ? (
        <DetailNavbar backTo={backTo} />
      ) : (
        <Navbar isHome={isHome} is404={is404} />
      )}
      {children}
      {showTabMenu ? <TabMenu /> : null}
      <Footer />
    </>
  );
};

export default LayoutUser;
