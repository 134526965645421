import { useDispatch, useSelector } from 'react-redux';
import LayoutUser from '../components/Layout/LayoutUser';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import {
  fetchActiveDrawdown,
  fetchHomeSeasonList,
  fetchLastWinners,
  fetchSocialProof,
} from '../actions/common';
import { setPrizeCurrency, thousandSeparator } from '../utils/math';
import { calculateTimeDifference, currentTimezone } from '../utils/date';
import ActionButton from '../components/Button/ActionButton';
import { useNavigate } from 'react-router-dom';
import CatLogo from '../assets/imgs/cat.png';
import CKUAnimation from '../assets/imgs/what-is-cku.png';
import Gameboy from '../assets/imgs/gameboy.png';
import LeftCoin from '../assets/imgs/left-coin.png';
import RightCoin from '../assets/imgs/right-coin.png';
import YellowEllipse from '../assets/imgs/yellow-bg-ellipse.png';
import HomeUsers from '../assets/imgs/home-users.png';
import HomeTelegram from '../assets/imgs/home-telegram.png';
import HomeDiscord from '../assets/imgs/home-discord.png';
import DiscordLogo from '../assets/imgs/discord-logo.png';
import TelegramLogo from '../assets/imgs/telegram-logo.png';
import SecondaryButton from '../components/Button/SecondaryButton';
import HowToPlay from '../components/Season/HowToPlay';
import Wallet from '../components/Modal/Wallet';
import HighestPrizeSeasonCard from '../components/Season/HighestPrizeSeasonCard';
import SeasonCard from '../components/Season/SeasonCard';

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    socialProof,
    activeDrawdown,
    lastWinners,
    seasonList,
    discordLink,
    telegramLink,
  } = useSelector((state) => state.common);
  const seasonListRef = useRef();
  const initialCount = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  const [countdown, setCountdown] = useState(initialCount);
  const [showWallet, setShowWallet] = useState(false);
  const proofs = [
    'claimed_prize',
    'registered_players',
    'tickets_sold',
    'winners',
  ];

  useEffect(() => {
    dispatch(fetchActiveDrawdown());
    dispatch(fetchLastWinners());
    dispatch(fetchSocialProof());
    dispatch(fetchHomeSeasonList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeDrawdown && activeDrawdown.draw_date) {
      var interval = setInterval(() => {
        if (calculateTimeDifference(activeDrawdown.draw_date)) {
          setCountdown(calculateTimeDifference(activeDrawdown.draw_date));
        } else {
          clearInterval(interval);
          setCountdown(initialCount);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDrawdown]);

  return (
    <LayoutUser showTabMenu={true} isHome={true}>
      {showWallet ? <Wallet closeModal={setShowWallet} /> : null}
      <section className="flex flex-col sm:flex-row justify-between">
        <div className="bg-blue w-full sm:w-1/2 h-[35vh] sm:h-[65vh] flex flex-col justify-center items-center text-white">
          <h3 className="text-[20px] font-poppins-semibold mb-[8px] uppercase text-center">
            Current Prize
          </h3>
          <p className="text-[20px] sm:text-[48px] font-poppins-bold uppercase text-center">
            {setPrizeCurrency(activeDrawdown)}{' '}
            {activeDrawdown
              ? thousandSeparator(activeDrawdown.winning_pot_budget)
              : 0}
          </p>
        </div>
        <div className="bg-red w-full sm:w-1/2 h-[35vh] sm:h-[65vh] flex flex-col justify-center items-center text-white">
          <h3 className="text-[20px] font-poppins-semibold mb-[8px] uppercase text-center">
            Countdown For Next Drawdown
          </h3>
          {activeDrawdown ? (
            <p className="text-sm font-poppins-semibold text-center w-[80%]">
              SEASON "{activeDrawdown.lottery_season.name}" DRAWDOWN "
              {activeDrawdown.drawdown.name}"
            </p>
          ) : null}
          <div className="flex flex-row items-center gap-[24px]">
            <span className="flex flex-col text-white font-poppins-semibold items-center w-[78px]">
              <p className="text-[24px] sm:text-[48px]">{countdown.days}</p>
              <p>Days</p>
            </span>
            <span className="flex flex-col text-white font-poppins-semibold items-center w-[78px]">
              <p className="text-[24px] sm:text-[48px]">{countdown.hours}</p>
              <p>Hours</p>
            </span>
            <span className="flex flex-col text-white font-poppins-semibold items-center w-[78px]">
              <p className="text-[24px] sm:text-[48px]">{countdown.minutes}</p>
              <p>Minutes</p>
            </span>
          </div>
        </div>
      </section>
      <section className="bg-yellow pb-[32px] sm:pb-0 relative">
        <div className="hidden sm:block bg-[url('./assets/imgs/cat-bg.png')] bg-contain bg-no-repeat bg-center w-full h-[800px] top-[8%] absolute z-0"></div>
        <img
          src={LeftCoin}
          alt="left-coin"
          className="hidden sm:block w-[170px] h-[180px] absolute top-[-5%] left-[18%]"
        />
        <img
          src={LeftCoin}
          alt="left-coin"
          className="hidden sm:block w-[170px] h-[180px] absolute top-[8%] left-[2%]"
        />
        <img
          src={RightCoin}
          alt="right-coin"
          className="hidden sm:block w-[170px] h-[180px] absolute top-[-50px] right-[3%]"
        />
        <img
          src={LeftCoin}
          alt="left-coin"
          className="hidden sm:block w-[170px] h-[180px] absolute top-[5%] right-[15%]"
        />
        <div className="w-full flex flex-col items-center relative z-10 sm:pb-[5%]">
          <ActionButton
            title="PLAY NOW"
            onClick={() => navigate('/play')}
            classes="!w-[296px] mt-[24px] mb-[10px]"
          />
          <div className="w-full h-[324px] sm:h-[60vh]">
            <img
              alt="cat icon"
              src={CatLogo}
              className="w-full h-full object-contain object-center"
            />
          </div>
          <div className="flex flex-col w-[95%] sm:w-[60%] mx-auto mt-[-100px] mb-[10px] rounded-[24px] border-4 border-black-100 shadow-thick bg-white-f relative ">
            <div className="w-full h-full bg-[url('./assets/imgs/home-lottery-bg.png')] opacity-20 absolute top-0 left-0"></div>
            <div className="px-[16px] py-[24px] rounded-tl-[24px] rounded-tr-[24px flex flex-col items-center border-b-2 border-black-100 relative z-10">
              <h2
                className="text-[48px] text-white-f font-poppins-extrabold text-center"
                style={{
                  textShadow: '4px 4px 0px #000',
                  WebkitTextStroke: '1px #000',
                }}
              >
                Last Winners
              </h2>
              <p className="mt-[8px] text-center text-lg">
                Join now to win big! Click ‘Play Now' and be our next success
                story.
              </p>
            </div>
            {lastWinners && lastWinners.length ? (
              <div className="flex flex-col sm:flex-row">
                {lastWinners.map((winner, idx) => (
                  <div
                    key={winner._id}
                    className={`${
                      idx === 0
                        ? 'bg-red'
                        : idx === 1
                        ? 'bg-[#01A1DB]'
                        : 'bg-yellow'
                    } sm:w-1/3 relative z-10 px-[24px] py-[8px] flex flex-col gap-[4px] justify-center items-center border-b-2 border-b-black-100`}
                  >
                    <h3 className="text-[24px] font-poppins-semibold text-center">
                      {winner.lottery_season.name}
                    </h3>
                    <p className="font-poppins-bold text-white-f text-[24px]">
                      {setPrizeCurrency(winner)}{' '}
                      {thousandSeparator(winner.winning_pot_budget)}
                    </p>
                    <p className="text-lg text-center">
                      {moment(winner.draw_date)
                        .tz(currentTimezone())
                        .format('MMMM Do')}{' '}
                      Winning Numbers:
                    </p>
                    <div className="flex flex-row gap-[8px] items-center justify-center">
                      {winner.winning_numbers.number_list.map((number, id) => (
                        <span
                          key={`${winner._id}-${id}-${number}`}
                          className="w-[26px] h-[26px] flex items-center justify-center text-sm font-poppins-semibold bg-black-100 text-white p-[8px] rounded-full"
                        >
                          {number}
                        </span>
                      ))}
                      <span className="w-[26px] border border-black-100 h-[26px] flex items-center justify-center text-sm font-poppins-semibold bg-red text-white p-[8px] rounded-full">
                        {winner.winning_numbers.extra_number}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <span className="bg-yellow text-[32px] font-poppins-semibold relative z-10 text-center py-[64px] border-b border-b-black-100">
                Coming Soon...
              </span>
            )}
            <div className="flex items-center justify-center p-[24px] rounded-bl-[24px] rounded-br-[24px]">
              <ActionButton
                title="SEE ALL ANNOUNCEMENT"
                classes="!bg-white !text-black-100 !w-fit relative z-10"
                iconName="mic-circle"
                iconSize={20}
                iconPos="left"
                onClick={() =>
                  navigate('/play?season_filter=Winners+Announcement')
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-normal sm:items-center sm:gap-[5%] sm:mt-[64px]">
          <div className="px-[16px] py-[24px] flex flex-col sm:bg-[url('./assets/imgs/red-bg.png')] sm:bg-contain sm:w-[30%] sm:min-h-[90vh] sm:justify-center sm:items-center sm:gap-[25px] sm:px-[64px]">
            <h2
              className="text-[48px] sm:text-[64px] text-white-f font-poppins-extrabold"
              style={{
                textShadow: '4px 4px 0px #000',
                WebkitTextStroke: '1px #000',
              }}
            >
              Playable Seasons
            </h2>
            <p className="mt-[8px] text-lg">
              Play the lottery without leaving your home - easy and convenient.
            </p>
            <SecondaryButton
              title="SEE ALL SEASONS"
              classes="!w-[95%] hidden sm:block"
              onClick={() => navigate(`/play`)}
            />
          </div>
          {seasonList.length ? (
            <div className="sm:flex sm:flex-col sm:w-[60%] sm:pr-[8px]">
              <div
                ref={seasonListRef}
                className="flex w-full flex-col sm:flex-row sm:justify-normal overflow-auto no-scrollbar sm:gap-[12px] justify-center items-center"
              >
                {seasonList.map((season) => (
                  <React.Fragment key={season._id}>
                    {seasonList.length === 1 ? (
                      <HighestPrizeSeasonCard season={season} isHome={true} />
                    ) : (
                      <SeasonCard season={season} isHome={true} />
                    )}
                  </React.Fragment>
                ))}
                <SecondaryButton
                  title="SEE ALL SEASONS"
                  classes="!w-[95%] sm:hidden"
                  onClick={() => navigate(`/play`)}
                />
              </div>
              {seasonList.length >= 3 ? (
                <div className="hidden sm:flex flex-row justify-between items-center">
                  <button
                    type="button"
                    onClick={() => {
                      const listRef = seasonListRef.current;
                      listRef.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                      });
                    }}
                  >
                    <ion-icon
                      name="chevron-back-circle-outline"
                      style={{ width: '47px', height: '47px' }}
                    ></ion-icon>
                  </button>
                  <p className="text-lg font-poppins-semibold">
                    TEST YOUR LUCK TODAY!
                  </p>
                  <button
                    type="button"
                    onClick={() => {
                      const listRef = seasonListRef.current;
                      listRef.scrollTo({
                        left: listRef.offsetLeft,
                        behavior: 'smooth',
                      });
                    }}
                  >
                    <ion-icon
                      name="chevron-forward-circle-outline"
                      style={{ width: '47px', height: '47px' }}
                    ></ion-icon>
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <p className="text-center font-poppins-semibold text-[32px]">
              Something cool is coming soon!
            </p>
          )}
        </div>
      </section>
      <section className="bg-[#01A1DB] bg-[url('./assets/imgs/line-bg.png')] bg-cover pb-[64px] w-full sm:py-[200px] relative">
        <img
          src={LeftCoin}
          alt="left-coin"
          className="hidden sm:block w-[170px] h-[180px] absolute top-[16%] left-[18%]"
        />
        <img
          src={RightCoin}
          alt="right-coin"
          className="hidden sm:block w-[170px] h-[180px] absolute top-[18%] right-[22%] rotate-[90deg]"
        />
        <div className="px-[16px] text-center py-[24px] flex flex-col">
          <h2
            className="text-[48px] text-white-f font-poppins-extrabold"
            style={{
              textShadow: '4px 4px 0px #000',
              WebkitTextStroke: '1px #000',
            }}
          >
            Social Proof
          </h2>
          <p className="mt-[8px] text-lg">
            Join our community of lucky players and play your way to success!
          </p>
        </div>
        {socialProof ? (
          <div className="flex flex-col items-center sm:flex-row w-full sm:border-y-2 sm:border-y-black-100">
            {proofs.map((proof, idx) => (
              <div
                key={`${proof}-${idx}`}
                className={`w-full h-[124px] flex flex-col p-[10px] justify-center items-center border-t border-t-black-100 last:border-b last:border-b-black-100 sm:border-b-0 sm:border-t-0 sm:border-r-2 sm:border-r-black-100 sm:last:border-r-0 ${
                  idx === 0
                    ? 'bg-[#00FFD1]'
                    : idx === 1
                    ? 'bg-[#F7D0E9]'
                    : idx === 2
                    ? 'bg-[#00FF6D]'
                    : idx === 3
                    ? 'bg-[#FFFDE7]'
                    : ''
                }`}
              >
                <h3
                  className={`${
                    proof === 'claimed_prize'
                      ? socialProof[`${proof}`] > 10000000
                        ? 'text-[24px]'
                        : socialProof[`${proof}`] > 1000000
                        ? 'text-[36px]'
                        : 'text-[48px]'
                      : 'text-[48px]'
                  } font-poppins-bold mb-[8px]`}
                >
                  {proof === 'claimed_prize' && 'CKU'}{' '}
                  {socialProof[`${proof}`]
                    ? thousandSeparator(socialProof[`${proof}`])
                    : 0}
                </h3>
                <p className="text-lg font-poppins-semibold">
                  {proof === 'registered_players'
                    ? 'Registered Players'
                    : proof === 'claimed_prize'
                    ? 'Claimed Prize'
                    : proof === 'winners'
                    ? 'Winners'
                    : proof === 'tickets_sold'
                    ? 'Lottery Sold'
                    : ''}
                </p>
              </div>
            ))}
          </div>
        ) : null}
      </section>
      <section className="bg-yellow py-[64px] relative">
        <div className="w-full h-[198px] hidden sm:block absolute top-[-160px] left-0">
          <img
            src={YellowEllipse}
            alt="yellow-ellipse"
            className=" w-full h-full object-contain"
          />
        </div>
        <img
          src={LeftCoin}
          alt="left-coin"
          className="hidden sm:block w-[170px] h-[180px] absolute top-0 left-[15%]"
        />
        <img
          src={RightCoin}
          alt="right-coin"
          className="hidden sm:block w-[170px] h-[180px] absolute top-[-100px] right-[25%] rotate-[23deg]"
        />
        <div className="px-[16px] text-center py-[24px] flex flex-col">
          <h2
            className="text-[48px] text-white-f font-poppins-extrabold"
            style={{
              textShadow: '4px 4px 0px #000',
              WebkitTextStroke: '1px #000',
            }}
          >
            How to Play?
          </h2>
          <p className="mt-[8px] text-lg">
            Get started today with our simple and easy lottery playing process!
          </p>
        </div>
        <HowToPlay isHome={true} />
      </section>
      <section className="bg-red py-[32px] flex flex-col items-center justify-center relative sm:flex-row">
        <img
          src={RightCoin}
          alt="right-coin"
          className="hidden sm:block w-[170px] h-[180px] absolute top-[-86px] left-[48px]"
        />
        <img
          src={Gameboy}
          alt="gameboy"
          className="w-[62px] h-[64px] absolute top-[-24px] left-[32px] sm:relative sm:w-[91px] sm:h-[91px] sm:top-0 sm:left-0"
        />
        <div className="px-[16px] text-center py-[24px] flex flex-col sm:w-[50%]">
          <h2
            className="text-[48px] text-white-f font-poppins-extrabold"
            style={{
              textShadow: '4px 4px 0px #000',
              WebkitTextStroke: '1px #000',
            }}
          >
            What is CKU?
          </h2>
          <p className="mt-[8px] font-poppins-semibold text-lg text-white">
            CKU is a publicly trade crypto currency available on the Latoken
            exchange.
          </p>
          <span
            className="underline cursor-pointer font-poppins-semibold text-lg mt-[10px] text-white"
            onClick={() =>
              window.open(
                'https://latoken.com/exchange/USDT',
                '_blank',
                'noopener,noreferrer'
              )
            }
          >
            Visit Latoken
          </span>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-full h-[220px]">
            <img
              src={CKUAnimation}
              alt="what-is-cku"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </section>
      <section className="bg-[#01A1DB] py-[64px] flex flex-col sm:flex-row sm:justify-between sm:items-end sm:px-[40px]">
        <img
          src={HomeDiscord}
          alt="home-discrod"
          className="hidden sm:block w-[240pxpx] h-[240px]"
        />
        <div className="px-[16px] text-center py-[24px] flex flex-col sm:w-[50%] gap-[12px]">
          <h2
            className="text-[36px] text-white-f font-poppins-extrabold"
            style={{
              textShadow: '4px 4px 0px #000',
              WebkitTextStroke: '1px #000',
            }}
          >
            JOIN OUR COMMUNITY
          </h2>
          <div className="flex flex-row items-center justify-center">
            <img
              src={HomeUsers}
              alt="community-users"
              className="w-[118px] h-full"
            />
            <p className="text-2xl font-poppins-semibold text-white ml-[4px]">
              + More
            </p>
          </div>
          <p className="mt-[8px] font-poppins-semibold text-lg text-white">
            Join now for exclusives, chats with fellow gamers, thrilling
            contests, and winners updates!
          </p>
          <div>
            <p className="text-lg text-white">Join now!</p>
            <div className="flex flex-col sm:flex-row items-center sm:gap-[8px]">
              <button
                type="button"
                className="bg-black-button py-[8px] px-[16px] flex flex-row justify-center items-center font-poppins-semibold text-lg text-center w-[90%] text-white border-[4px] border-black-100 mb-[10px] h-[60px] sm:mb-0"
                onClick={() => {
                  if (discordLink !== 'Coming Soon') {
                    window.open(discordLink, '_blank', 'noopener,noreferrer');
                  }
                }}
              >
                <img
                  src={DiscordLogo}
                  className="w-[36px] h-[29px] mr-[10px]"
                  alt="discord-logo"
                />
                {discordLink.replace('https://', '').trim()}
              </button>
              <button
                type="button"
                className="bg-black-button py-[8px] px-[16px] flex flex-row justify-center items-center font-poppins-semibold text-lg text-center w-[90%] text-white border-[4px] border-black-100 h-[60px]"
                onClick={() => {
                  if (telegramLink !== 'Coming Soon') {
                    window.open(telegramLink, '_blank', 'noopener,noreferrer');
                  }
                }}
              >
                <img
                  src={TelegramLogo}
                  className="w-[36px] h-[32px] mr-[10px]"
                  alt="telegram-logo"
                />
                {telegramLink.replace('https://', '').trim()}
              </button>
            </div>
          </div>
        </div>
        <img
          src={HomeTelegram}
          alt="home-telegram"
          className="hidden sm:block w-[240pxpx] h-[240px]"
        />
        <div className="sm:hidden flex flex-row items-center justify-center mt-[15px]">
          <img
            src={HomeDiscord}
            alt="home-discord"
            className="w-[144px] h-[144px]"
          />
          <img
            src={HomeTelegram}
            alt="home-telegram"
            className="w-[144px] h-[144px]"
          />
        </div>
      </section>
    </LayoutUser>
  );
};

export default HomePage;
