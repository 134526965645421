import { useForm } from 'react-hook-form';
import Modal from './Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePassword } from '../../utils/validationForm';
import getToastMessage from '../../utils/toastMessage';
import ActionButton from '../Button/ActionButton';
import InputPassword from '../Input/InputPassword';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordStep3 } from '../../actions/auth';
import { setForgotPassPayload } from '../../slices/authSlice';
import { enqueueSnackbar } from 'notistack';

const ChangePassword = ({ closeModal }) => {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(changePassword),
  });
  const forgotPassPayload = useSelector(
    (state) => state.auth.forgotPassPayload
  );
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    let payload = { ...forgotPassPayload, ...data };
    const done = await dispatch(forgotPasswordStep3(payload));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        dispatch(setForgotPassPayload(null));
        closeModal(false);
      }
    }
  };

  return (
    <Modal closeModal={closeModal} title="Change Password">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputPassword
          register={register}
          errors={formState.errors}
          title="New Password"
          name="new_password"
          placeholder="Password"
        />
        <InputPassword
          register={register}
          errors={formState.errors}
          title="Confirm New Password"
          name="confirm_new_password"
          placeholder="Password"
        />
        <ActionButton type="submit" title="Change Password" />
      </form>
    </Modal>
  );
};

export default ChangePassword;
