import Modal from './Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { dateRangeFilter } from '../../utils/validationForm';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import SecondaryButton from '../Button/SecondaryButton';
import ActionButton from '../Button/ActionButton';
import InputDate from '../Input/InputDate';
import { CURRENT_TIME } from '../../utils/date';

const DateRange = ({
  closeModal,
  setter,
  selector,
  stateName,
  additionalState,
}) => {
  const { control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(dateRangeFilter),
  });
  const dateFilter = useSelector((state) => state[selector][stateName]);
  const dispatch = useDispatch();
  const initialValue = {
    startDate: '',
    endDate: '',
  };
  const watchStartDate = watch('startDate', CURRENT_TIME);

  useEffect(() => {
    if (dateFilter && (dateFilter.startDate || dateFilter.endDate)) {
      const formattedFilter = {
        startDate: new Date(dateFilter.startDate),
        endDate: new Date(dateFilter.endDate),
      };
      reset(formattedFilter);
    }
  }, [dateFilter, reset]);

  const onSubmit = (data) => {
    let payload = JSON.parse(JSON.stringify(data));
    if (additionalState) payload = { ...payload, ...additionalState };
    dispatch(setter(payload));
    closeModal(false);
  };

  const clearAll = () => {
    dispatch(setter(initialValue));
    closeModal(false);
  };

  return (
    <Modal title="Date Range" closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="w-full">
          <InputDate title="Start Date" control={control} name="startDate" />
        </div>
        <InputDate
          title="End Date"
          control={control}
          name="endDate"
          minDate={new Date(watchStartDate)}
        />
        <div className="flex flex-row justify-between items-center mt-[24px]">
          <SecondaryButton
            title="Discard All"
            onClick={clearAll}
            classes="!w-[48%] !text-base"
          />
          <ActionButton
            title="Apply"
            type="submit"
            classes="!w-[48%] !text-base"
          />
        </div>
      </form>
    </Modal>
  );
};

export default DateRange;
