import { useEffect, useState } from 'react';
import Modal from './Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setTicketPageData } from '../../slices/playerSlice';
import SecondaryButton from '../Button/SecondaryButton';
import ActionButton from '../Button/ActionButton';

const StatusFilter = ({ closeModal }) => {
  const [selected, setSelected] = useState('');
  const pageData = useSelector((state) => state.player.ticketPageData);
  const dispatch = useDispatch();
  const options = ['All', 'Open', 'Closed', 'Win'];

  useEffect(() => {
    if (pageData.ticket_status) {
      setSelected(pageData.ticket_status);
    }
  }, [pageData]);

  const applyFilter = () => {
    if (selected !== pageData.ticket_status) {
      dispatch(setTicketPageData({ ticket_status: selected }));
    }
    closeModal(false);
  };

  const clearAll = () => {
    dispatch(setTicketPageData({ ticket_status: '' }));
    closeModal(false);
  };

  return (
    <Modal title="Lottery Status" closeModal={closeModal}>
      <div className="flex flex-wrap gap-[8px] w-full justify-between">
        {options.map((option, idx) => (
          <button
            key={idx}
            onClick={() => setSelected(option)}
            className={`border-[0.5px] border-black-100 rounded-[4px] py-[6px] font-poppins-semibold min-w-[48%] ${
              option === selected
                ? 'bg-black-100 text-white'
                : 'bg-white text-black-100'
            }`}
          >
            {option}
          </button>
        ))}
      </div>
      <div className="flex flex-row justify-between items-center mt-[24px]">
        <SecondaryButton
          title="Discard All"
          onClick={clearAll}
          classes="!w-[48%] !text-base"
        />
        <ActionButton
          title="Apply"
          onClick={applyFilter}
          classes="!w-[48%] !text-base"
        />
      </div>
    </Modal>
  );
};

export default StatusFilter;
