import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { setPrizeCurrency, thousandSeparator } from '../../utils/math';
import { currentTimezone } from '../../utils/date';
import ActionButton from '../Button/ActionButton';

const HighestPrizeSeasonCard = ({ season, isHome }) => {
  const { usdPrice } = useSelector((state) => state.common);
  const navigate = useNavigate();

  return (
    <div
      key={season.lottery_season._id}
      className={`relative z-10 flex flex-col p-[16px] mb-[16px] border border-black-100 text-white items-center justify-center shadow-thick sm:mb-[52px] bg-[#404144] sm:flex-row sm:justify-start sm:gap-[24px] sm:w-[80%] ${
        isHome && '!bg-red sm:!w-full'
      }`}
    >
      <div
        className={`w-full h-[151px] rounded-lg ${
          season.banner && season.banner.image
            ? 'bg-transparent'
            : 'bg-grey-banner'
        } sm:w-[70%] sm:h-[290px]`}
      >
        {season.banner && season.banner.image ? (
          <img
            src={season.banner.image}
            alt={`${season.lottery_season.name} banner`}
            className="w-full h-full object-cover rounded-lg"
          />
        ) : null}
      </div>
      <div
        className={`w-full flex flex-col items-center justify-center sm:w-[28%] sm:!items-start sm:!justify-start`}
      >
        <div
          className={`w-full border-b border-b-white text-center my-[4px] pb-[4px] sm:!text-left`}
        >
          <h3 className="text-[24px] font-poppins-semibold">
            {setPrizeCurrency(season)}{' '}
            {thousandSeparator(season.active_drawdown.winning_pot_budget)}
          </h3>
          {/* <p className="text-lg">
            USD{' '}
            {thousandSeparator(
              season.active_drawdown.winning_pot_budget * usdPrice
            )}
          </p> */}
        </div>
        <p
          className={`font-poppins-semibold text-lg uppercase my-[4px] text-blue ${
            isHome && '!text-white'
          }`}
        >
          {season.lottery_season.name}
        </p>
        <p className="text-sm font-poppins-semibold">
          Draw Date:{' '}
          {season.active_drawdown && season.active_drawdown.draw_date
            ? moment(season.active_drawdown.draw_date)
                .tz(currentTimezone())
                .format('DD-MM-YYYY, HH:mm')
            : '-'}
        </p>
        <ActionButton
          type="button"
          onClick={() => navigate(`/play/${season.lottery_season.id}`)}
          iconName={season.status === 'Finished' && 'mic-circle'}
          iconSize="20"
          iconSrc={season.status !== 'Finished' && '8-ball'}
          iconPos="left"
          title={
            season.status === 'Finished'
              ? 'WATCH NOW!'
              : season.active_drawdown.status === 'Finished'
              ? 'CHECK NOW!'
              : 'PLAY NOW!'
          }
          classes={`mt-[4px] bg-green ${isHome && '!bg-black-button'}`}
        />
      </div>
    </div>
  );
};

export default HighestPrizeSeasonCard;
