import { MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedAnnouncement,
  setSelectedDrawdownInfo,
} from '../../slices/seasonSlice';

const SelectDrawdown = () => {
  const { drawdownList, selectedSeasonInfo, selectedDrawdownInfo } =
    useSelector((state) => state.season);
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col px-[16px] sm:px-0">
      <p className="text-sm mb-[4px]">Current Draw Down</p>
      <Select
        value={selectedDrawdownInfo.drawdown.id}
        onChange={(ev) => {
          const selected = drawdownList.filter(
            (draw) => draw.drawdown.id === ev.target.value
          );
          if (selected.length) {
            dispatch(setSelectedDrawdownInfo(selected[0]));
            dispatch(
              setSelectedAnnouncement(
                selected[0].announcement_list.length
                  ? selected[0].announcement_list[0]
                  : null
              )
            );
          }
        }}
        MenuProps={{
          style: { maxHeight: 300 },
          sx: {
            '&& .Mui-selected': {
              backgroundColor: '#FFD600',
            },
          },
        }}
        SelectDisplayProps={{
          style: { padding: '8px' },
        }}
        sx={{
          width: '80%',
          fontFamily: 'Poppins',
          fontSize: '12px',
          marginBottom: '16px',
          boxShadow: 'none',
          border: '1px solid #212121',
          borderRadius: '6px',
          backgroundColor: '#f7f7f7',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              border: 0,
            },
        }}
      >
        {drawdownList.map((drawdown) => (
          <MenuItem
            style={{
              fontFamily: 'Poppins',
              padding: '8px',
              minHeight: 'fit-content',
              fontSize: '12px',
            }}
            value={drawdown.drawdown.id}
            key={drawdown.drawdown.id}
          >
            {drawdown.drawdown.name}{' '}
            {drawdown.drawdown.id ===
              selectedSeasonInfo.active_drawdown.drawdown.id && '(Active)'}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectDrawdown;
