import React, { useEffect, useState } from 'react';
import { fetchPlayerTransactionDetail } from '../actions/player';
import { useParams } from 'react-router-dom';
import LayoutUser from '../components/Layout/LayoutUser';
import CheckmarkOutline from '../assets/imgs/checkmark-circle.png';
import ActionButton from '../components/Button/ActionButton';
import SecondaryButton from '../components/Button/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import moment from 'moment-timezone';
import { thousandSeparator } from '../utils/math';
import ReferFriends from '../components/Modal/ReferFriends';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import Logo from '../assets/imgs/invoice-logo.png';
import { currentTimezone } from '../utils/date';

const InvoicePage = () => {
  const { isLoading, invoice } = useSelector((state) => state.player);
  const [showReferFriends, setShowReferFriends] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { copyText } = useCopyToClipboard();

  useEffect(() => {
    dispatch(fetchPlayerTransactionDetail({ id }));
  }, [dispatch, id]);

  return (
    <LayoutUser>
      {isLoading ? <Loading /> : null}
      {showReferFriends ? (
        <ReferFriends closeModal={setShowReferFriends} />
      ) : null}
      <div
        className={`flex flex-col w-full h-full sm:flex-row sm:justify-between bg-yellow sm:h-[110vh]`}
      >
        <div className="hidden sm:block bg-blue w-full h-[166px] sm:w-[55%] sm:h-full"></div>
        <div className="flex flex-col mx-auto items-center w-[85%] h-full pt-[85px] pb-[24px] sm:pb-0 sm:w-[45%]">
          {invoice ? (
            <div className="relative flex flex-col border border-black-100 shadow-thick p-[24px] bg-white-f gap-[16px] w-full sm:w-[80%]">
              <img
                src={CheckmarkOutline}
                alt="checkmark-circle"
                className="w-[83px] h-[83px] absolute z-10 top-[-60px] left-1/2 transform -translate-x-1/2"
              />
              <div className="w-[150px] h-[70px] m-auto">
                <img
                  src={Logo}
                  alt="lc88 logo"
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-2xl font-poppins-semibold text-blue">
                {invoice.is_paid ? 'Purchase Complete' : 'Not Paid'}
              </h3>
              <div className="flex flex-col pb-[16px] border-dashed border-b-black-100 border-b-2">
                <h5 className="text-lg font-poppins-semibold mb-[8px]">
                  Payment Information
                </h5>
                <div className="flex flex-row justify-between items-center text-sm mb-[3px]">
                  <p className="font-poppins-medium w-1/2">Transaction ID</p>
                  <p className="w-1/2">{invoice.transaction_id}</p>
                </div>
                <div className="flex flex-row justify-between items-center text-sm mb-[3px]">
                  <p className="font-poppins-medium w-1/2">Purchase Date</p>
                  <p className="w-1/2">
                    {moment(invoice.createdAt)
                      .tz(currentTimezone())
                      .format('DD-MM-YYYY, HH:mm')}
                  </p>
                </div>
                <div className="flex flex-row justify-between items-center text-sm mb-[3px]">
                  <p className="font-poppins-medium w-1/2">Amount</p>
                  <p className="w-1/2">
                    CKU {thousandSeparator(invoice.total_payment)}
                  </p>
                </div>
                <div className="flex flex-row justify-between items-center text-sm mb-[3px]">
                  <p className="font-poppins-medium w-1/2">Season</p>
                  <p className="w-1/2">{invoice.lottery_season.name}</p>
                </div>
              </div>
              <div className="flex flex-col">
                <h3 className="text-lg font-poppins-semibold mb-[10px]">
                  My Tickets
                </h3>
                <div className="max-h-[20vh] overflow-auto">
                  {invoice.ticket_list.map((drawTicket, idx) => (
                    <React.Fragment key={`ticket-D${idx}`}>
                      {Object.values(drawTicket)[0].map((ticket, idxTicket) => (
                        <div
                          key={`${ticket.ticket_id}-t-${idx}`}
                          className="w-[98%] flex flex-col px-[12px] py-[8px] mb-[4px] border border-black-100 shadow-thick rounded-lg bg-white"
                        >
                          <p className="text-sm font-poppins-semibold text-right mb-[4px]">
                            {ticket.drawdown.name} Ticket #{idxTicket + 1}
                          </p>
                          <div className="flex flex-row gap-[8px] justify-center">
                            {ticket.ticket_number.map((num, numIdx) => (
                              <span
                                key={`${invoice.transaction_id}-t-${idx}-${numIdx}`}
                                className="text-sm w-[26px] h-[26px] bg-black-100 rounded-full flex items-center justify-center font-poppins-bold text-white"
                              >
                                {num}
                              </span>
                            ))}
                          </div>
                          <div className="flex flex-row justify-between opacity-50 font-poppins-semibold mt-[4px] text-sm">
                            Ticket ID{' '}
                            <p className="font-poppins">{ticket.ticket_id}</p>
                          </div>
                        </div>
                      ))}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <ActionButton
                title="Refer Friends"
                iconName="link-outline"
                iconPos="left"
                iconSize={20}
                onClick={() => setShowReferFriends(true)}
              />
              <SecondaryButton
                title="Download Tickets"
                onClick={() =>
                  copyText(
                    invoice.ticket_list
                      .flatMap((obj) =>
                        Object.values(obj)[0].map(
                          (entry) => entry.ticket_number
                        )
                      )
                      .join('\n'),
                    'Successfully copied ticket list'
                  )
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    </LayoutUser>
  );
};

export default InvoicePage;
