import { useSelector } from 'react-redux';
import { setPrizeCurrency, thousandSeparator } from '../../utils/math';
import CoinLogo from '../../assets/imgs/coin.png';

const WinnerBreakdown = () => {
  const { selectedSeasonInfo } = useSelector((state) => state.season);
  const { usdPrice } = useSelector((state) => state.common);

  return (
    <>
      {selectedSeasonInfo &&
      selectedSeasonInfo.active_drawdown &&
      selectedSeasonInfo.active_drawdown.prize_list &&
      selectedSeasonInfo.active_drawdown.prize_list.length ? (
        <div className="flex flex-col px-[16px] sm:px-[36px]">
          <h3 className="font-poppins-semibold text-base">
            Breakdown Total Prize
          </h3>
          {selectedSeasonInfo.active_drawdown.prize_list.map((prize) => (
            <div
              className="flex flex-row items-center gap-[8px] mb-[2px] last:mb-0"
              key={prize._id}
            >
              <span className="text-sm font-poppins-bold w-[15%] sm:w-[10%]">
                <ion-icon
                  name="trophy-outline"
                  style={{ width: '12px', height: '12px', marginRight: '4px' }}
                ></ion-icon>
                {prize.prize_number === 1
                  ? '1st'
                  : prize.prize_number === 2
                  ? '2nd'
                  : prize.prize_number === 3
                  ? '3rd'
                  : ''}
              </span>
              <span className="flex flex-row items-center gap-[4px]">
                {/* <img
                  src={CoinLogo}
                  alt="coin-logo"
                  className="w-[16px] h-[16px]"
                /> */}
                <p className="text-sm">
                  {setPrizeCurrency(selectedSeasonInfo)}{' '}
                  {thousandSeparator(prize.current_pot)}
                  {/* <span className="text-black-70 ml-[4px]">
                    (USD {thousandSeparator(prize.current_pot * usdPrice)})
                  </span> */}
                </p>
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default WinnerBreakdown;
