import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataLimit from '../Table/DataLimit';
import { setTicketPageData } from '../../slices/playerSlice';
import StyledTableContainer from '../Table/StyledTableContainer';
import StyledTable from '../Table/StyledTable';
import SecondaryButton from '../Button/SecondaryButton';
import { TableBody, TableHead, TableRow } from '@mui/material';
import StyledTableCell from '../Table/StyledTableCell';
import { fetchPlayerTicketHistory } from '../../actions/player';
import StatusFilter from '../Modal/StatusFilter';
import DateRange from '../Modal/DateRange';
import InputSearch from '../Input/InputSearch';
import Pagination from '../Table/Pagination';
import moment from 'moment-timezone';
import { setPrizeCurrency, thousandSeparator } from '../../utils/math';
import { currentTimezone } from '../../utils/date';

const TicketHistory = () => {
  const { ticketPageData, ticketTotalPages, ticketHistory } = useSelector(
    (state) => state.player
  );
  const [modalSection, setModalSection] = useState('');
  const dispatch = useDispatch();

  const onSearch = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== ticketPageData.search) {
      const search = ev.target.value;
      dispatch(setTicketPageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== ticketPageData.page) {
      dispatch(setTicketPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    dispatch(fetchPlayerTicketHistory(ticketPageData));
  }, [dispatch, ticketPageData]);

  return (
    <>
      {modalSection === 'status_filter' ? (
        <StatusFilter closeModal={setModalSection} />
      ) : modalSection === 'date_filter' ? (
        <DateRange
          closeModal={setModalSection}
          setter={setTicketPageData}
          selector="player"
          stateName="ticketPageData"
        />
      ) : null}
      <InputSearch
        title="Search"
        onSearch={onSearch}
        placeholder="Ticket Number, Lottery Number or Status"
      />
      <DataLimit
        initialLimit={ticketPageData.dataLimit}
        setter={setTicketPageData}
        classList="mb-[16px]"
      />
      <div className="flex flex-row gap-[10px] mb-[16px]">
        <SecondaryButton
          title="Status Filter"
          classes="!text-base !w-fit !border-2"
          onClick={() => setModalSection('status_filter')}
        />
        <SecondaryButton
          title="Date Filter"
          classes="!text-base !w-fit !border-2"
          onClick={() => setModalSection('date_filter')}
        />
      </div>
      {ticketHistory.length ? (
        <div>
          <StyledTableContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Ticket ID</StyledTableCell>
                  <StyledTableCell>Ticket Status</StyledTableCell>
                  <StyledTableCell>Season</StyledTableCell>
                  <StyledTableCell>Draw ID</StyledTableCell>
                  <StyledTableCell>Lottery Number</StyledTableCell>
                  <StyledTableCell>Player ID</StyledTableCell>
                  <StyledTableCell>Purchase Date</StyledTableCell>
                  <StyledTableCell>Prize</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ticketHistory.map((ticket) => (
                  <TableRow key={ticket._id}>
                    <StyledTableCell>{ticket.ticket_id}</StyledTableCell>
                    <StyledTableCell>{ticket.ticket_status}</StyledTableCell>
                    <StyledTableCell>
                      {ticket.lottery_season.name}
                    </StyledTableCell>
                    <StyledTableCell>{ticket.drawdown.id}</StyledTableCell>
                    <StyledTableCell>
                      <div className="flex flex-row gap-[2px]">
                        {ticket.ticket_number.map((ticket, idx) => (
                          <span
                            className="w-[16px] h-[16px] text-[9px] rounded-full bg-black-100 flex items-center justify-center text-white font-poppins-semibold"
                            key={`${ticket.ticket_id}-${idx}`}
                          >
                            {ticket}
                          </span>
                        ))}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>{ticket.player_id}</StyledTableCell>
                    <StyledTableCell>
                      {moment(ticket.createdAt)
                        .tz(currentTimezone())
                        .format('DD-MM-YYYY, HH:mm')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {setPrizeCurrency(ticket)}{' '}
                      {thousandSeparator(ticket.prize)}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
          <Pagination
            totalPages={ticketTotalPages}
            pageData={ticketPageData}
            onChange={handlePageChange}
          />
        </div>
      ) : (
        <p>Data not found!</p>
      )}
    </>
  );
};

export default TicketHistory;
