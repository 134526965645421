import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDrawdownPurchase } from '../../slices/seasonSlice';

const SelectMultipleDrawdown = () => {
  const {
    selectedDrawdownPurchase,
    availableDrawdownList,
    selectedSeasonInfo,
  } = useSelector((state) => state.season);
  const dispatch = useDispatch();

  const handleChange = (ev) => {
    let value = ev.target.value;
    value = [...new Map(value.map((item) => [item['_id'], item])).values()]; // make sure no duplicate drawdown
    console.log(value, '====value');
    dispatch(setSelectedDrawdownPurchase(value));
  };

  return (
    <div className="flex flex-col mb-[16px]">
      <p className="text-sm mb-[4px]">Select Draw Down</p>
      <Select
        value={selectedDrawdownPurchase}
        renderValue={() => {
          if (selectedDrawdownPurchase.length > 1)
            return 'Multiple draw down selected';
          else
            return `${selectedDrawdownPurchase[0].drawdown.name}${
              selectedDrawdownPurchase[0]._id ===
              selectedSeasonInfo.active_drawdown._id
                ? ' (Active)'
                : ''
            }`;
        }}
        onChange={handleChange}
        multiple
        MenuProps={{
          style: { maxHeight: 300 },
        }}
        SelectDisplayProps={{
          style: { padding: '8px' },
        }}
        sx={{
          width: '100%',
          fontFamily: 'Poppins',
          fontSize: '12px',
          boxShadow: 'none',
          border: '1px solid #212121',
          borderRadius: '6px',
          backgroundColor: '#f7f7f7',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              border: 0,
            },
        }}
      >
        {availableDrawdownList.map((drawdown) => (
          <MenuItem
            style={{
              fontFamily: 'Poppins',
              padding: '0px',
              minHeight: 'fit-content',
              fontSize: '12px',
            }}
            value={drawdown}
            key={drawdown._id}
          >
            <Checkbox
              checked={
                selectedDrawdownPurchase.findIndex(
                  (draw) => draw._id === drawdown._id
                ) >= 0
              }
            />
            <ListItemText
              primaryTypographyProps={{
                style: { fontFamily: 'Poppins', fontSize: '12px' },
              }}
              primary={`${drawdown.drawdown.name}${
                drawdown._id === selectedSeasonInfo.active_drawdown._id
                  ? ' (Active)'
                  : ''
              }`}
            />
          </MenuItem>
        ))}
      </Select>
      {!selectedDrawdownPurchase.length ? (
        <p className="text-red text-[10px]">Please select a draw down</p>
      ) : selectedDrawdownPurchase.length > 1 ? (
        <div className="flex flex-row flex-wrap mt-[10px] gap-[8px]">
          {selectedDrawdownPurchase.map((drawdown) => (
            <span
              key={drawdown._id}
              className="bg-white flex flex-row gap-[10px] items-center font-poppins-semibold text-sm border-2 border-black-100 p-[4px]"
            >
              <p>
                {drawdown.drawdown.name}
                {drawdown._id === selectedSeasonInfo.active_drawdown._id
                  ? ' (Active)'
                  : ''}
              </p>
              <button
                type="button"
                className="flex"
                onClick={() => {
                  dispatch(
                    setSelectedDrawdownPurchase(
                      selectedDrawdownPurchase.filter(
                        (d) => d._id !== drawdown._id
                      )
                    )
                  );
                }}
              >
                <ion-icon
                  name="close-circle"
                  style={{ width: '14.5px', height: '14.5px' }}
                ></ion-icon>
              </button>
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default SelectMultipleDrawdown;
