import ActionButton from '../Button/ActionButton';
import Modal from './Modal';
import CoinLogo from '../../assets/imgs/coin.png';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalTickets, thousandSeparator } from '../../utils/math';
import { purchaseTicket } from '../../actions/season';
import getToastMessage from '../../utils/toastMessage';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

const CheckoutConfirm = ({ closeModal }) => {
  const dispatch = useDispatch();
  const {
    selectedSeasonInfo,
    tempGeneratedTickets,
    selectedTickets,
    selectedDrawdownPurchase,
  } = useSelector((state) => state.season);
  const navigate = useNavigate();

  const purchase = async () => {
    if (selectedTickets.length >= 6) {
      const payload = {
        lottery_season_id: selectedSeasonInfo.lottery_season.id,
        drawdown_id_list: selectedDrawdownPurchase.map((d) => d.drawdown.id),
        ticket_number_list: selectedTickets,
      };
      const done = await dispatch(purchaseTicket(payload));
      if (done) {
        const { message, variant } = getToastMessage(done);
        if (variant === 'success') {
          const { payload } = done;
          if (payload && payload.details && payload.details.transaction_id) {
            navigate(`/invoice/${payload.details.transaction_id}`);
          }
          closeModal(false);
          enqueueSnackbar(message, { variant });
        }
      }
    }
  };

  return (
    <Modal closeModal={closeModal} title="Checkout Confirmation">
      <div className="flex flex-col text-sm gap-[4px] mb-[16px]">
        <div className="flex flex-row">
          <p className="font-poppins-semibold w-1/2">Season</p>
          <span className="flex flex-col w-1/2">
            <p>{selectedSeasonInfo.lottery_season.name}</p>
            <p>
              {selectedDrawdownPurchase.map((d) => d.drawdown.name).join(', ')}
            </p>
          </span>
        </div>
        <div className="flex flex-row">
          <p className="font-poppins-semibold w-1/2">Amount of Tickets</p>
          <p className="w-1/2">{getTotalTickets(tempGeneratedTickets)}x</p>
        </div>
        <div className="flex flex-row">
          <p className="font-poppins-semibold w-1/2">Ticket Price</p>
          <p className="w-1/2">
            CKU {thousandSeparator(selectedSeasonInfo.ticket_price)}
          </p>
        </div>
        <div className="flex flex-row items-center text-base font-poppins-bold">
          <p className="w-1/2">Total</p>
          <span className="flex flex-row items-center gap-[6px] w-1/2">
            <img src={CoinLogo} alt="coin-logo" className="w-[26px] h-[26px]" />
            <p>
              {thousandSeparator(
                getTotalTickets(tempGeneratedTickets) *
                  selectedSeasonInfo.ticket_price
              )}
            </p>
          </span>
        </div>
      </div>
      <ActionButton title="Confirm" onClick={purchase} />
    </Modal>
  );
};

export default CheckoutConfirm;
