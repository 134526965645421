import { useState } from 'react';
import { useSelector } from 'react-redux';
import CoinLogo from '../../assets/imgs/coin.png';
import { thousandSeparator } from '../../utils/math';
import Wallet from '../Modal/Wallet';

const CoinButton = ({ classes, ...props }) => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [showWallet, setShowWallet] = useState(false);

  return (
    <>
      {showWallet ? <Wallet closeModal={setShowWallet} /> : null}
      {userInfo ? (
        <button
          type="button"
          className={`bg-white flex items-center gap-[10px] border-[2px] border-black-100 py-[8px] px-[16px] font-poppins-semibold text-lg text-center w-fit ${classes}`}
          {...props}
          onClick={() => setShowWallet(true)}
        >
          <img
            src={CoinLogo}
            alt="coin logo"
            style={{ width: '26.5px', height: '26.5px' }}
          />
          {thousandSeparator(userInfo.lucky_coin_balance)}
        </button>
      ) : null}
    </>
  );
};

export default CoinButton;
