import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { serverAPI } from '../utils/API';
import { setPlayerBalance } from '../slices/authSlice';

export const checkTransactionId = createAsyncThunk(
  'wallet/checkTransactionId',
  async (transactionData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post('webGetLuckyCoin', transactionData);
      if (data && data.new_player_balance !== undefined) {
        dispatch(setPlayerBalance(data.new_player_balance));
      }
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendLuckyCoinInternal = createAsyncThunk(
  'wallet/sendLuckyCoinInternal',
  async (transactionData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post(
        'webSendLuckyCoinInternal',
        transactionData
      );
      if (data && data.new_player_balance !== undefined) {
        dispatch(setPlayerBalance(data.new_player_balance));
      }
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const withdrawLuckyCoin = createAsyncThunk(
  'wallet/withdrawLuckyCoin',
  async (withdrawData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post(
        'webWithdrawLuckyCoin',
        withdrawData
      );
      if (data && data.new_player_balance !== undefined) {
        dispatch(setPlayerBalance(data.new_player_balance));
      }
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendTransferOTP = createAsyncThunk(
  'wallet/sendTransferOTP',
  async (withdrawData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('webSendTransferOTP', withdrawData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendWithdrawOTP = createAsyncThunk(
  'wallet/sendWithdrawOTP',
  async (withdrawData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('webSendWithdrawOTP', {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendConnectWalletOTP = createAsyncThunk(
  'wallet/sendConnectWalletOTP',
  async (walletData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        'webSendConnectWalletOTP',
        walletData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkReceiverName = createAsyncThunk(
  'wallet/checkReceiverName',
  async (walletData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('webCheckReceiverName', walletData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const connectNewWallet = createAsyncThunk(
  'wallet/connectNewWallet',
  async (walletData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('webConnectWallet', walletData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editWalletInfo = createAsyncThunk(
  'wallet/editWalletInfo',
  async (walletData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('webEditWallet', walletData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchWalletList = createAsyncThunk(
  'wallet/fetchWalletList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('playerInfo', {});
      return data.player_data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
