import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import Modal from '../Modal/Modal';
import getToastMessage from '../../utils/toastMessage';
import { editWalletInfo } from '../../actions/wallet';

const DeleteWallet = ({ walletDetail, closeModal }) => {
  const dispatch = useDispatch();

  const onDeleteWallet = async () => {
    const walletData = JSON.parse(JSON.stringify(walletDetail));
    walletData.is_deleted = true;

    const done = await dispatch(editWalletInfo(walletData));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(false);
      }
    }
  };

  return (
    <Modal title="Delete Wallet" closeModal={closeModal}>
      <h3 className="text-base">Are you certain about deleting this wallet?</h3>
      <div className="flex flex-row justify-between items-center mt-[24px]">
        <button
          onClick={() => closeModal(false)}
          type="button"
          className="w-[45%] px-[16px] py-[8px] text-red border rounded-[20px] font-poppins-semibold"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onDeleteWallet}
          className="w-[45%] px-[16px] py-[8px] bg-black-100 text-white border rounded-[20px] font-poppins-semibold"
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteWallet;
