import { useDispatch } from 'react-redux';
import { MenuItem, Select } from '@mui/material';
import { useState } from 'react';

const DataLimit = ({
  setter,
  initialLimit = 10,
  pageState = 'page',
  classList = '',
}) => {
  const dispatch = useDispatch();
  const [openSelect, setOpenSelect] = useState(false);
  const onLimitChange = (ev) => {
    if (ev.target.value !== initialLimit) {
      dispatch(setter({ dataLimit: ev.target.value, [`${pageState}`]: 0 }));
    }
  };

  return (
    <div
      className={`flex flex-row text-sm items-center gap-[4px] ${classList}`}
    >
      <p>Show</p>
      <div className="relative">
        {/* <select
          defaultValue={initialLimit}
          onChange={onLimitChange}
          className="appearance-none cursor-pointer bg-white p-[8px] pr-[24px] border border-black-100 rounded-[6px] w-full outline-none"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select> */}
        <Select
          value={initialLimit}
          open={openSelect}
          onOpen={() => setOpenSelect(true)}
          onClose={() => setOpenSelect(false)}
          onChange={(ev) => onLimitChange(ev)}
          SelectDisplayProps={{
            style: {
              padding: '8px',
              paddingRight: '26px',
              fontFamily: 'Poppins',
              fontSize: '12px',
              border: '1px solid #212121',
              borderRadius: '6px',
            },
          }}
          sx={{ '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' } }}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
        {/* <span className="absolute right-[8px] top-[11px] cursor-pointer">
          <ion-icon name="caret-down-outline"></ion-icon>
        </span> */}
      </div>
      <p>row(s) in each page</p>
    </div>
  );
};

export default DataLimit;
