import { useSelector } from 'react-redux';
import { getTotalTickets, thousandSeparator } from '../../utils/math';
import ActionButton from '../Button/ActionButton';
import CoinLogo from '../../assets/imgs/coin.png';
import { useState } from 'react';
import CheckoutConfirm from '../Modal/CheckoutConfirm';
import { enqueueSnackbar } from 'notistack';

const Checkout = () => {
  const { selectedSeasonInfo, tempGeneratedTickets } = useSelector(
    (state) => state.season
  );
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      {showConfirm ? <CheckoutConfirm closeModal={setShowConfirm} /> : null}
      <div className="flex flex-row justify-between items-center fixed z-[30] bottom-0 right-0 w-full bg-white px-[16px] py-[10px] sm:py-[12.5px] shadow-checkout border-t border-t-black-100">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <p className="font-poppins-semibold mb-[2px] sm:text-[24px] sm:mr-[28px]">
            Total
          </p>
          <div className="flex flex-row items-center">
            <img
              src={CoinLogo}
              alt="coin logo"
              style={{ width: '26.5px', height: '26.5px' }}
            />
            <p className="font-poppins-semibold ml-[6px] sm:text-[24px]">
              {selectedSeasonInfo
                ? thousandSeparator(
                    getTotalTickets(tempGeneratedTickets) *
                      selectedSeasonInfo.ticket_price
                  )
                : 0}
            </p>
          </div>
        </div>
        <ActionButton
          title="Checkout"
          classes="!w-[60%] sm:!w-[30%]"
          onClick={() => {
            if (!tempGeneratedTickets || !tempGeneratedTickets.length) {
              enqueueSnackbar('No tickets selected', { variant: 'warning' });
            } else {
              setShowConfirm(true);
            }
          }}
        />
      </div>
    </>
  );
};

export default Checkout;
