import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import MyTickets from './MyTickets';
import { getPlayerTicketListByDrawdown } from '../../actions/season';
import SeasonTitle from './SeasonTitle';
import SecondaryButton from '../Button/SecondaryButton';
import BuyTicket from '../Modal/BuyTicket';
import { enqueueSnackbar } from 'notistack';
import { CURRENT_TIME } from '../../utils/date';
import VideoAnnoucement from './VideoAnnouncement';
import SelectDrawdown from './SelectDrawdown';
import { setSelectedDrawdownPurchase } from '../../slices/seasonSlice';

const WinAnnouncement = () => {
  const {
    drawdownList,
    selectedDrawdownInfo,
    selectedAnnouncement,
    selectedSeasonInfo,
    availableDrawdownList,
    tempGeneratedTickets,
    selectedDrawdownPurchase,
  } = useSelector((state) => state.season);
  const [showBuyTicket, setShowBuyTicket] = useState(false);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const activeDrawdownRef = useRef();
  const drawdownListRef = useRef();

  useEffect(() => {
    if (selectedDrawdownInfo) {
      dispatch(
        getPlayerTicketListByDrawdown({
          lottery_season_id: selectedSeasonInfo.lottery_season.id,
          drawdown_id_list: [selectedDrawdownInfo.drawdown.id],
        })
      );

      if (activeDrawdownRef && activeDrawdownRef.current) {
        const activeOffsetLeft = activeDrawdownRef.current.offsetLeft;
        drawdownListRef.current.scrollTo({
          left: width > 640 ? activeOffsetLeft - 250 : activeOffsetLeft,
          behavior: 'smooth',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDrawdownInfo]);

  const checkShowBuyButton = () => {
    if (
      selectedSeasonInfo &&
      moment(CURRENT_TIME).isAfter(selectedSeasonInfo.startDate) &&
      selectedDrawdownInfo &&
      (selectedDrawdownInfo.status === 'Active' ||
        availableDrawdownList.findIndex(
          (d) => d._id === selectedDrawdownInfo._id
        ) >= 0) &&
      (!selectedDrawdownInfo.purchase_freeze_time ||
        (selectedDrawdownInfo.purchase_freeze_time &&
          moment(CURRENT_TIME).isBefore(
            selectedDrawdownInfo.purchase_freeze_time
          )))
    ) {
      return true;
    }

    return false;
  };

  const addDrawdownToPurchase = () => {
    if (
      tempGeneratedTickets.length &&
      selectedDrawdownPurchase.findIndex(
        (d) => d._id === selectedDrawdownInfo._id
      ) < 0
    ) {
      dispatch(
        setSelectedDrawdownPurchase([
          ...selectedDrawdownPurchase,
          selectedDrawdownInfo,
        ])
      );
    } else if (!tempGeneratedTickets || !tempGeneratedTickets.length) {
      dispatch(setSelectedDrawdownPurchase([selectedDrawdownInfo]));
    }
  };

  return (
    <>
      {showBuyTicket ? <BuyTicket closeModal={setShowBuyTicket} /> : null}
      <div className="sm:px-[24px] py-[24px] flex flex-col sm:flex-row sm:justify-between">
        <div className="sm:w-1/2">
          {drawdownList.length && selectedDrawdownInfo ? (
            <SelectDrawdown />
          ) : null}

          <VideoAnnoucement />

          <SeasonTitle winAnnoun={true} />
          {/* ======== MY TICKETS ======== */}
          <div className="px-[16px] flex flex-col items-center sm:hidden">
            <MyTickets />
            {checkShowBuyButton() ? (
              <SecondaryButton
                title="Buy New Ticket"
                classes="mt-[10px] !w-[80%]"
                onClick={() => {
                  if (
                    !selectedDrawdownInfo.purchase_freeze_time ||
                    (selectedDrawdownInfo.purchase_freeze_time &&
                      moment(CURRENT_TIME).isBefore(
                        selectedDrawdownInfo.purchase_freeze_time
                      ))
                  ) {
                    addDrawdownToPurchase();
                    setShowBuyTicket(true);
                  } else {
                    enqueueSnackbar(
                      'You can no longer purchase tickets for this drawdown',
                      { variant: 'warning' }
                    );
                  }
                }}
              />
            ) : null}
          </div>
          {/* ======== MY TICKETS ======== */}

          {selectedAnnouncement ? (
            <div className="w-full sm:w-[49%] py-[16px] sm:py-0 px-[16px] sm:px-0">
              <p className="font-poppins-semibold mb-[4px]">Annoucements</p>
              <p className="text-sm">{selectedAnnouncement.announcement}</p>
            </div>
          ) : null}
        </div>
        <div className="px-[24px] sm:w-[48%] hidden sm:flex sm:items-center sm:flex-col">
          <MyTickets />
          {checkShowBuyButton() ? (
            <SecondaryButton
              title="Buy New Ticket"
              classes="mt-[10px] !w-[80%]"
              onClick={() => {
                if (
                  !selectedDrawdownInfo.purchase_freeze_time ||
                  (selectedDrawdownInfo.purchase_freeze_time &&
                    moment(CURRENT_TIME).isBefore(
                      selectedDrawdownInfo.purchase_freeze_time
                    ))
                ) {
                  addDrawdownToPurchase();
                  setShowBuyTicket(true);
                } else {
                  enqueueSnackbar(
                    'You can no longer purchase tickets for this drawdown',
                    { variant: 'warning' }
                  );
                }
              }}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default WinAnnouncement;
