import { useEffect, useState } from 'react';
import Modal from './Modal';
import InputSearch from '../Input/InputSearch';
import { useDispatch, useSelector } from 'react-redux';
import { setFriendsPageData } from '../../slices/playerSlice';
import DataLimit from '../Table/DataLimit';
import { TableBody, TableHead, TableRow } from '@mui/material';
import StyledTableContainer from '../Table/StyledTableContainer';
import StyledTable from '../Table/StyledTable';
import StyledTableCell from '../Table/StyledTableCell';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { fetchPlayerFriendsList } from '../../actions/player';
import Loading from '../Loading';
import Pagination from '../Table/Pagination';

const ReferFriends = ({ closeModal }) => {
  const [selectedSection, setSelectedSection] = useState('Referral');
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { friendsPageData, friendsList, friendsTotalPages, isLoading } =
    useSelector((state) => state.player);
  const { copyText } = useCopyToClipboard();
  const sections = ['Referral', 'Friends List'];

  const onSearch = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== friendsPageData.search) {
      const search = ev.target.value;
      dispatch(setFriendsPageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== friendsPageData.page) {
      dispatch(setFriendsPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    if (selectedSection === 'Friends List') {
      dispatch(fetchPlayerFriendsList(friendsPageData));
    }
  }, [dispatch, friendsPageData, selectedSection]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <Modal closeModal={closeModal} title="Refer Friends">
        <div className="flex flex-row w-full border border-black-100 rounded-lg mb-[16px]">
          {sections.map((section, idx) => (
            <button
              className={`w-1/2 first:border-r first:border-r-black-100 first:rounded-tl-lg first:rounded-bl-lg last:rounded-br-lg last:rounded-tr-lg text-lg py-[10px] font-poppins-semibold ${
                selectedSection === section
                  ? 'bg-yellow'
                  : 'bg-white opacity-50'
              }`}
              key={`${section}-${idx}`}
              onClick={() => setSelectedSection(section)}
            >
              {section}
            </button>
          ))}
        </div>
        {selectedSection === 'Referral' ? (
          <div className="flex flex-col">
            <div className="flex flex-col px-[12px] py-[4px] mb-[8px]">
              <h3 className="text-lg font-poppins-bold text-blue mb-[4px]">
                Easy Refer
              </h3>
              <p className="text-sm">
                Earn 5% on EVERY lottery ticket your friends buy if they
                register with your referral link. Also earn 5% of their winnings
                when they win.
              </p>
            </div>
            <div className="flex flex-col w-full items-center gap-[8px]">
              <h5 className="text-sm font-poppins-bold">
                Share and earn today!
              </h5>
              <div className="border border-black-100 bg-light-yellow px-[12px] py-[4px] flex flex-col w-full">
                <h5 className="text-sm font-poppins-bold">Referral Link</h5>
                <div className="flex flex-row justify-between items-center mt-[8px]">
                  <p className="text-sm font-poppins-bold text-light-green w-[80%]">
                    {userInfo.referral_tag}
                  </p>
                  <button
                    type="button"
                    className="flex"
                    onClick={() =>
                      copyText(
                        userInfo.referral_tag,
                        'Successfully copied Referral Link'
                      )
                    }
                  >
                    <ion-icon
                      name="copy-outline"
                      style={{ width: '24px', height: '24px' }}
                    ></ion-icon>
                  </button>
                </div>
              </div>
              <h5 className="text-sm font-poppins-bold">or</h5>
              <div className="border border-black-100 bg-light-yellow px-[12px] py-[4px] flex flex-col w-full">
                <h5 className="text-sm font-poppins-bold">Referral Code</h5>
                <div className="flex flex-row justify-between items-center mt-[8px]">
                  <p className="text-sm font-poppins-bold text-light-green">
                    {userInfo.player_id}
                  </p>
                  <button
                    type="button"
                    className="flex"
                    onClick={() =>
                      copyText(
                        userInfo.player_id,
                        'Successfully copied Referral Code'
                      )
                    }
                  >
                    <ion-icon
                      name="copy-outline"
                      style={{ width: '24px', height: '24px' }}
                    ></ion-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : selectedSection === 'Friends List' ? (
          <div className="flex flex-col">
            <h3 className="text-base font-poppins-semibold mb-[16px]">
              Friend List
            </h3>
            <InputSearch
              title="Search"
              placeholder="Player Email"
              onSearch={onSearch}
              classes="sm:w-full"
            />
            <DataLimit
              initialLimit={friendsPageData.dataLimit}
              setter={setFriendsPageData}
              classList="mb-[16px]"
            />
            {friendsList.length ? (
              <div>
                <StyledTableContainer style={{ background: 'transparent' }}>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{ background: 'transparent' }}>
                          Email
                        </StyledTableCell>
                        <StyledTableCell style={{ background: 'transparent' }}>
                          Tickets Amount
                        </StyledTableCell>
                        <StyledTableCell style={{ background: 'transparent' }}>
                          Wins Amount
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {friendsList.map((friend) => (
                        <TableRow key={friend.email}>
                          <StyledTableCell
                            style={{ background: 'transparent' }}
                          >
                            {friend.email}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ background: 'transparent' }}
                          >
                            {friend.total_tickets}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ background: 'transparent' }}
                          >
                            {friend.winning_tickets}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
                <Pagination
                  totalPages={friendsTotalPages}
                  pageData={friendsPageData}
                  onChange={handlePageChange}
                />
              </div>
            ) : (
              <p>Data not found!</p>
            )}
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default ReferFriends;
