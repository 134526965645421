import { useForm } from 'react-hook-form';
import LCAmount from './LCAmount';
import { yupResolver } from '@hookform/resolvers/yup';
import { withdrawLC } from '../../utils/validationForm';
import Input from '../Input/Input';
import ActionButton from '../Button/ActionButton';
import SecondaryButton from '../Button/SecondaryButton';
import { useEffect, useState } from 'react';
import ConfirmTNC from './ConfirmTNC';
import { useDispatch, useSelector } from 'react-redux';
import { sendWithdrawOTP } from '../../actions/wallet';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { setLCData } from '../../slices/walletSlice';
import ActiveWallet from './ActiveWallet';

const WithdrawLC = ({ setOtpModal }) => {
  const { register, handleSubmit, formState, watch, setValue } = useForm({
    resolver: yupResolver(withdrawLC),
  });
  const userInfo = useSelector((state) => state.auth.userInfo);
  const activeWallet = useSelector((state) => state.wallet.activeWallet);
  const watchAmount = watch('lucky_coin_amount', 0);
  const [agreedTNC, setAgreedTNC] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (agreedTNC) {
      if (watchAmount > userInfo.lucky_coin_balance) {
        enqueueSnackbar('You do not have enough lucky coin!', {
          variant: 'warning',
        });
        return;
      }
      const done = await dispatch(sendWithdrawOTP(data));
      if (done) {
        const { message, variant } = getToastMessage(done);
        if (variant === 'success') {
          dispatch(setLCData({ type: 'withdraw', data }));
          setOtpModal('withdraw');
          enqueueSnackbar(message, { variant });
        }
      }
    } else if (!agreedTNC) {
      enqueueSnackbar('Please check and read the Terms and Conditions', {
        variant: 'warning',
      });
    }
  };

  useEffect(() => {
    if (activeWallet && activeWallet.wallet_address) {
      setValue('wallet_address', activeWallet.wallet_address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWallet]);

  return (
    <>
      <LCAmount showNote={true} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row w-full justify-between items-end mb-[18px]">
          <Input
            title="Input Amount"
            type="number"
            name="lucky_coin_amount"
            placeholder="CKU"
            classes="w-[40%] sm:w-[50%] !mb-0"
            register={register}
            errors={formState.errors}
          />
          <div>
            <SecondaryButton
              title="50%"
              classes="!w-fit h-fit ml-[4px] !px-[12px] !py-[4px]"
              onClick={() => {
                if (userInfo) {
                  setValue(
                    'lucky_coin_amount',
                    0.5 * userInfo.lucky_coin_balance
                  );
                }
              }}
            />
            <SecondaryButton
              title="100%"
              classes="!w-fit h-fit mx-[4px] !px-[12px] !py-[4px]"
              onClick={() => {
                if (userInfo) {
                  setValue('lucky_coin_amount', userInfo.lucky_coin_balance);
                }
              }}
            />
          </div>
        </div>
        <ActiveWallet />
        <ConfirmTNC TNCState={agreedTNC} setTNCState={setAgreedTNC} />
        <ActionButton
          title={`Withdraw ${watchAmount ? `${watchAmount} CKU` : ''}`}
          type="submit"
          classes="mb-[4px]"
        />
      </form>
    </>
  );
};

export default WithdrawLC;
