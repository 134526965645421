import { useDispatch } from 'react-redux';
import ActionButton from '../Button/ActionButton';
import SecondaryButton from '../Button/SecondaryButton';
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';
import { setUserInfo } from '../../slices/authSlice';

const Logout = ({ closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.removeItem('USER_KEY');
    dispatch(setUserInfo(null));
    navigate('/login');
  };

  return (
    <Modal closeModal={closeModal} title="Logout" hideCloseBtn={true}>
      <h3 className="text-lg font-poppins">
        Are you sure you want to log out?
      </h3>
      <div className="flex flex-row justify-between w-full items-center mt-[16px] pb-[4px] px-[4px]">
        <SecondaryButton
          title="Cancel"
          classes="!w-[47%]"
          onClick={() => closeModal(false)}
        />
        <ActionButton
          title="Log Out"
          classes="!w-[47%] bg-red"
          onClick={onLogout}
        />
      </div>
    </Modal>
  );
};

export default Logout;
