export var CURRENT_TIME = new Date();

export const checkSameDate = (firstDate, secondDate) => {
  firstDate = new Date(firstDate);
  secondDate = new Date(secondDate);

  if (
    firstDate.getDate() === secondDate.getDate() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
  ) {
    return true;
  }

  return false;
};

export const jakartaCurrentTime = (time) => {
  if (time) {
    time = new Date(time);
    setInterval(() => {
      time.setSeconds(time.getSeconds() + 1);
      CURRENT_TIME = time;
    }, 1000);
  }
};

export const currentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatTimeFromSeconds = (seconds) => {
  if (typeof seconds !== 'number' || isNaN(seconds) || seconds <= 0) {
    return false;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return `${formattedMinutes}:${formattedSeconds}`;
};

function formatWithLeadingZero(value) {
  return value < 10 ? `0${value}` : `${value}`;
}

export const calculateTimeDifference = (targetDate) => {
  const currentDate = CURRENT_TIME;
  targetDate = new Date(targetDate);
  const timeDifference = targetDate - currentDate;

  if (timeDifference < 0) return null;

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  const formattedDays = formatWithLeadingZero(days);
  const formattedHours = formatWithLeadingZero(hours);
  const formattedMinutes = formatWithLeadingZero(minutes);
  const formattedSeconds = formatWithLeadingZero(seconds);

  return {
    days: formattedDays,
    hours: formattedHours,
    minutes: formattedMinutes,
    seconds: formattedSeconds,
  };
};
