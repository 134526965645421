import { useNavigate } from 'react-router-dom';
import ActionButton from '../Button/ActionButton';
import { useSelector } from 'react-redux';
import { useIsLoggedIn } from '../../hooks/useAuth';
import CoinButton from '../Button/CoinButton';

const DetailNavbar = ({ backTo }) => {
  const isLoggedin = useIsLoggedIn();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const navigate = useNavigate();

  const backToPrevious = () => {
    navigate(backTo);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'instant' });
    }, 100);
  };

  return (
    <div className="flex flex-row items-center justify-between w-full py-[10px] px-[16px] sm:px-[48px] bg-yellow border border-black-100">
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={backToPrevious}
      >
        <button className="flex" type="button">
          <ion-icon
            name="chevron-back-circle-outline"
            style={{ width: '24px', height: '24px' }}
          ></ion-icon>
        </button>
        <p className="hidden sm:block font-poppins-semibold text-lg ml-[8px]">
          Back
        </p>
      </div>
      {userInfo && isLoggedin ? (
        <CoinButton />
      ) : (
        <ActionButton title="SIGN IN/UP" classes="!w-fit !bg-red" />
      )}
    </div>
  );
};

export default DetailNavbar;
