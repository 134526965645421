import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSelectedTabMenu } from '../../slices/navbarSlice';
import { useEffect } from 'react';
import { useIsLoggedIn } from '../../hooks/useAuth';

const TabMenu = () => {
  const { tabMenus, selectedTab } = useSelector((state) => state.navbar);
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const pathname = location.pathname;
    const selected = tabMenus.filter((tab) => tab.path === pathname);
    if (selected.length) {
      dispatch(setSelectedTabMenu(selected[0].name));
    }
  }, [dispatch, location.pathname, tabMenus]);

  return (
    <div className="fixed w-[95%] z-20 bottom-[10px] left-[10px] right-[10px] rounded-[99px] bg-black-button shadow-tab-menu flex flex-row justify-center items-center py-[8px] px-[24px] text-sm sm:hidden gap-[16px]">
      {tabMenus.map((tab) => (
        <div
          key={tab.iconName}
          className={`flex flex-col rounded-lg justify-center items-center p-[8px] font-poppins-semibold w-[20%] text-white ${
            selectedTab === tab.name && 'bg-blue'
          }`}
          onClick={() => {
            if (tab.path) {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              if (
                (tab.path === '/history' || tab.path === '/profile') &&
                !isLoggedIn
              ) {
                navigate('/login');
              } else {
                navigate(tab.path);
              }
            }
          }}
        >
          <ion-icon
            name={tab.iconName}
            style={{ width: '20px', height: '20px' }}
          ></ion-icon>
          <p className="mt-[2px]">{tab.name}</p>
        </div>
      ))}
    </div>
  );
};

export default TabMenu;
