import { useState } from 'react';

const InputPassword = ({
  title,
  placeholder,
  register,
  name,
  width = '100%',
  errors,
}) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <div className={`flex flex-col mb-[18px] w-[${width}]`}>
      <label className="text-sm">{title}</label>
      <div className="relative">
        <input
          type={showPass ? 'text' : 'password'}
          placeholder={placeholder}
          {...register(name)}
          className="w-full text-sm p-[8px] border border-black-100 rounded-[6px] outline-0 bg-white"
        />
        <button
          type="button"
          className="flex absolute top-[8px] right-[8px]"
          onClick={() => setShowPass(!showPass)}
        >
          <ion-icon
            name={showPass ? 'eye-off-outline' : 'eye-outline'}
            style={{ width: '16px', height: '16px' }}
          ></ion-icon>
        </button>
      </div>
      {errors && errors[name] && (
        <p className="text-xs text-red">{errors[name].message}</p>
      )}
    </div>
  );
};

export default InputPassword;
