import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPassOTP } from '../../utils/validationForm';
import { formatTimeFromSeconds } from '../../utils/date';
import Input from '../Input/Input';
import ActionButton from '../Button/ActionButton';
import { useDispatch } from 'react-redux';
import {
  registerPlayerResendOTP,
  registerPlayerStep2,
} from '../../actions/auth';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';

const RegisterOTP = ({ closeModal, email }) => {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(forgotPassOTP),
  });
  const dispatch = useDispatch();
  const [countdown, setCountDown] = useState(300);

  const onSubmit = async (data) => {
    console.log(data, '====data');
    let payload = JSON.parse(JSON.stringify(data));
    payload.email = email;
    const done = await dispatch(registerPlayerStep2(payload));
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        closeModal(false);
      }
    }
  };

  useEffect(() => {
    if (countdown) {
      const interval = setInterval(() => {
        setCountDown((prevState) => (prevState -= 1));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countdown]);

  return (
    <Modal closeModal={closeModal} title="OTP for Registration">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          errors={formState.errors}
          title="OTP Number"
          name="otp"
          placeholder="Input OTP sent to your email"
        />
        <p className="text-lg mb-[16px]">
          Don't received?{' '}
          <span
            className={`text-blue cursor-pointer font-poppins-bold ${
              !countdown && 'underline'
            }`}
            onClick={() => {
              if (!countdown) {
                dispatch(registerPlayerResendOTP({ email })).then(() =>
                  setCountDown(300)
                );
              }
            }}
          >
            {!countdown
              ? 'Send OTP'
              : `Try again in ${formatTimeFromSeconds(countdown)}`}
          </span>
        </p>
        <ActionButton type="submit" title="Login" />
      </form>
    </Modal>
  );
};

export default RegisterOTP;
