import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchPagesContent = createAsyncThunk(
  'common/fetchPagesContent',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('webPagesContentList', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchContactPlatform = createAsyncThunk(
  'common/fetchContactPlatform',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('contactPlatformList', {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchActiveDrawdown = createAsyncThunk(
  'common/fetchActiveDrawdown',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('getActiveDrawdown', {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchSocialProof = createAsyncThunk(
  'common/fetchSocialProof',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('getSocialProof', {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchLastWinners = createAsyncThunk(
  'common/fetchLastWinners',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('getLastLotteryWinners', {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchHomeSeasonList = createAsyncThunk(
  'common/fetchHomeSeasonList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('webLotterySeasonList', {
        params: { season_filter: 'New Season' },
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUSDPrice = createAsyncThunk(
  'common/getUSDPrice',
  async (params, { rejectWithValue }) => {
    try {
      const { data, duration } = await serverAPI.get('webGetUSDPrice', {});
      data.duration = duration;
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
