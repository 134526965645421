import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPassOTP } from '../../utils/validationForm';
import { formatTimeFromSeconds } from '../../utils/date';
import Input from '../Input/Input';
import ActionButton from '../Button/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendLuckyCoinInternal,
  sendTransferOTP,
  sendWithdrawOTP,
  withdrawLuckyCoin,
} from '../../actions/wallet';
import getToastMessage from '../../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';
import { setLCData } from '../../slices/walletSlice';
import Modal from '../Modal/Modal';

const SendWithdrawOTP = ({ closeModal, section }) => {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(forgotPassOTP),
  });
  const { sendLCData, withdrawLCData } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const [countdown, setCountDown] = useState(300);

  const onSubmit = async (data) => {
    console.log(data, '====data');
    let payload = JSON.parse(JSON.stringify(data));
    let lcData =
      section === 'send'
        ? JSON.parse(JSON.stringify(sendLCData))
        : JSON.parse(JSON.stringify(withdrawLCData));
    if (lcData) lcData = { ...lcData, ...payload };

    const done = await dispatch(
      section === 'send'
        ? sendLuckyCoinInternal(lcData)
        : withdrawLuckyCoin(lcData)
    );
    if (done) {
      const { message, variant } = getToastMessage(done);
      if (variant === 'success') {
        enqueueSnackbar(message, { variant });
        dispatch(setLCData({ type: 'reset', data: null }));
        closeModal(1);
      }
    }
  };

  useEffect(() => {
    if (countdown) {
      const interval = setInterval(() => {
        setCountDown((prevState) => (prevState -= 1));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countdown]);

  return (
    <Modal
      closeModal={closeModal}
      title={section === 'send' ? 'Send CKU OTP' : 'Withdraw CKU OTP'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          errors={formState.errors}
          title="OTP Number"
          name="otp"
          placeholder="Input OTP sent to your email"
        />
        <p className="text-lg mb-[16px]">
          Don't received?{' '}
          <span
            className={`text-blue cursor-pointer font-poppins-bold ${
              !countdown && 'underline'
            }`}
            onClick={() => {
              if (!countdown) {
                dispatch(
                  section === 'send'
                    ? sendTransferOTP({ email: sendLCData.email })
                    : sendWithdrawOTP()
                ).then(() => setCountDown(300));
              }
            }}
          >
            {!countdown
              ? 'Send OTP'
              : `Try again in ${formatTimeFromSeconds(countdown)}`}
          </span>
        </p>
        <ActionButton type="submit" title="Confirm" />
      </form>
    </Modal>
  );
};

export default SendWithdrawOTP;
