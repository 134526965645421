import { useState } from 'react';
import { useSelector } from 'react-redux';
import { setPrizeCurrency, thousandSeparator } from '../../utils/math';

const MyTickets = ({ classes }) => {
  const { playerTicketList, selectedDrawdownInfo, playerWinningTickets } =
    useSelector((state) => state.season);
  const sections = ['Winning Number', 'My Tickets'];
  const winnerData = ['1st', '2nd', '3rd'];
  const [selectedSection, setSelectedSection] = useState(sections[0]);

  return (
    <div className={`flex flex-col border border-black-100 w-full ${classes}`}>
      <div className="flex flex-row items-center border-b border-b-black-100">
        {sections.map((section, idx) => (
          <span
            key={`section-win-${idx}`}
            className={`p-[8px] w-1/2 first:border-r first:border-r-black-100 font-poppins-semibold text-sm text-center cursor-pointer ${
              selectedSection === section && 'bg-black-100 text-white'
            }`}
            onClick={() => setSelectedSection(section)}
          >
            {section}
          </span>
        ))}
      </div>
      {selectedSection === 'My Tickets' ? (
        <div className="max-h-[50vh] overflow-auto flex flex-col gap-[8px]">
          {playerWinningTickets ? (
            <div className="bg-yellow">
              {playerWinningTickets.map((ticket, idx) => (
                <div
                  key={`ticket ${idx}`}
                  className="flex flex-col px-[12px] py-[8px] gap-[4px] border-b border-b-black-100"
                >
                  <div className="flex flex-row font-poppins-semibold justify-evenly items-center text-lg mb-[4px]">
                    <p>#{ticket.ticket_id}</p>
                    <p>Win!</p>
                  </div>
                  <div className="flex flex-row gap-[8px] justify-center">
                    {ticket.ticket_number.map((num, id) => (
                      <span
                        key={`t-${idx} ${id}`}
                        className="text-sm w-[26px] h-[26px] bg-black-100 rounded-full flex items-center justify-center font-poppins-bold text-white"
                      >
                        {num}
                      </span>
                    ))}
                  </div>
                  <p className="text-2xl text-green font-poppins-semibold text-center">
                    {setPrizeCurrency(ticket)} {thousandSeparator(ticket.prize)}
                  </p>
                  <span className="flex flex-row items-center justify-center">
                    <ion-icon
                      name="trophy-outline"
                      style={{ width: '16px', height: '16px' }}
                    ></ion-icon>
                    <p className="text-lg font-poppins-semibold ml-[8px]">
                      {ticket.ticket_status.split(' ')[1]} Place
                    </p>
                  </span>
                </div>
              ))}
            </div>
          ) : null}
          {playerTicketList.length ? (
            playerTicketList
              .filter((txt) => !txt.ticket_status.includes('Win'))
              .map((ticket, idx) => (
                <div
                  key={`ticket ${idx}`}
                  className="flex flex-col px-[12px] py-[8px] gap-[4px] border-b border-b-black-100"
                >
                  <div className="flex flex-row justify-evenly items-center text-lg mb-[4px]">
                    <p>#{ticket.ticket_id}</p>
                    <p>{ticket.ticket_status}</p>
                  </div>
                  <div className="flex flex-row gap-[8px] justify-center">
                    {ticket.ticket_number.map((num, id) => (
                      <span
                        key={`t-${idx} ${id}`}
                        className="text-sm w-[26px] h-[26px] bg-black-100 rounded-full flex items-center justify-center font-poppins-bold text-white"
                      >
                        {num}
                      </span>
                    ))}
                  </div>
                </div>
              ))
          ) : (
            <p className="text-center pb-[8px]">
              You haven't purchased a ticket for this drawdown.
            </p>
          )}
        </div>
      ) : selectedSection === 'Winning Number' ? (
        <div className="p-[24px] pt-[8px]">
          {selectedDrawdownInfo &&
          selectedDrawdownInfo.winning_numbers.number_list.length ? (
            <div className="flex flex-col">
              <div className="flex flex-row gap-[8px] items-center justify-center">
                {selectedDrawdownInfo.winning_numbers.number_list.map(
                  (number, id) => (
                    <span
                      key={`${selectedDrawdownInfo._id}-${id}-${number}`}
                      className="w-[26px] h-[26px] flex items-center justify-center text-sm font-poppins-semibold bg-black-100 text-white p-[8px] rounded-full"
                    >
                      {number}
                    </span>
                  )
                )}
                <span className="w-[26px] h-[26px] flex items-center justify-center text-sm font-poppins-semibold bg-red text-white p-[8px] rounded-full">
                  {selectedDrawdownInfo.winning_numbers.extra_number}
                </span>
              </div>
              <h4 className="font-poppins-semibold text-green text-base text-center mt-[4px]">
                Prize distributed: {setPrizeCurrency(selectedDrawdownInfo)}{' '}
                {thousandSeparator(
                  Math.floor(selectedDrawdownInfo.total_distributed)
                )}
              </h4>
              <div className="flex flex-col mt-[16px]">
                {winnerData.map((winner) => (
                  <div
                    className="flex flex-row items-center"
                    key={`${selectedDrawdownInfo._id}-winner-${winner}`}
                  >
                    <ion-icon
                      name="trophy-outline"
                      style={{ width: '16px', height: '16px' }}
                    ></ion-icon>
                    <p className="ml-[18px] text-lg w-[50%]">{winner} Place</p>
                    <p className="text-lg">
                      {selectedDrawdownInfo.winner_data[`${winner}`]} players
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            'The Winning Number has not been set yet'
          )}
        </div>
      ) : null}
    </div>
  );
};

export default MyTickets;
