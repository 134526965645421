import { useEffect, useState } from 'react';
import ActionButton from '../Button/ActionButton';
import ExchangeWallet from '../Modal/ExchangeWallet';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import LCAmount from './LCAmount';
import ActiveWallet from './ActiveWallet';
import { useDispatch } from 'react-redux';
import { fetchWalletList } from '../../actions/wallet';

const GetLC = () => {
  const [modal, setModal] = useState('');
  const { copyText } = useCopyToClipboard();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWalletList());
  }, [dispatch]);

  return (
    <>
      {modal === 'get_cku' ? <ExchangeWallet closeModal={setModal} /> : null}
      <div className="flex flex-col pb-[16px]">
        <div className="flex flex-col mb-[16px] bg-light-yellow border border-black-100 p-[8px] gap-[4px]">
          <h3 className="font-poppins-semibold text-sm">Exchange</h3>
          <div>
            <LCAmount section="Get CKU" />
            {/* <p className="text-sm opacity-50">
              <span className="font-poppins-semibold">Note: </span>Your CKU will
              automatically converted into CKU after a after a successful
              transfer for your convenience to play.
            </p> */}
          </div>
        </div>
        <div className="flex flex-col bg-light-yellow border border-black-100 p-[8px] mb-[16px]">
          <h3 className="font-poppins-semibold text-sm">
            How to get CKU to play:
          </h3>
          <p className="text-sm ml-[4px]">
            <span className="font-poppins-semibold">1. </span>
            Connect your{' '}
            <span className="font-poppins-semibold text-blue">
              Latoken
            </span>{' '}
            wallet address.
          </p>
          <p className="text-sm ml-[4px]">
            <span className="font-poppins-semibold">2. </span>
            Click <span className="font-poppins-semibold">Get CKU</span>,
            purchase and transfer CKU to LuckyCat88 Wallet Address.
          </p>
        </div>
        <ActiveWallet />
        <div className="flex flex-col mb-[16px]">
          <h3 className="font-poppins-semibold text-sm">
            LuckyCat88 Latoken Wallet Address
          </h3>
          <div
            className="text-sm flex flex-row justify-between bg-light-yellow items-center w-full p-[8px] border border-black-100 cursor-pointer"
            onClick={() =>
              copyText(
                process.env.REACT_APP_API === 'production'
                  ? 'deposit@luckycat88.com'
                  : 'support@luckycat88.com',
                'Succefully copied email'
              )
            }
          >
            <p className="font-poppins-semibold">
              {process.env.REACT_APP_API === 'production'
                ? 'deposit@luckycat88.com'
                : 'support@luckycat88.com'}
            </p>
            <button type="button" className="flex ml-[4px]">
              <ion-icon
                name="copy-outline"
                style={{ width: '20px', height: '20px' }}
              ></ion-icon>
            </button>
          </div>
        </div>
        <ActionButton
          title="Get CKU"
          classes="text-lg"
          onClick={() => setModal('get_cku')}
        />
      </div>
    </>
  );
};

export default GetLC;
