import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';
import { setPlayerBalance } from '../slices/authSlice';

export const fetchSeasonList = createAsyncThunk(
  'season/seasonList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('webLotterySeasonList', { params });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchSeasonInfo = createAsyncThunk(
  'season/seasonInfo',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('webLotterySeasonDetail', {
        params,
      });
      return data.lottery_season;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchDrawdownList = createAsyncThunk(
  'season/drawdownList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('webDrawdownList', { params });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchDrawdownInfo = createAsyncThunk(
  'season/dradownInfo',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('webDrawdownDetail', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPlayerTicketList = createAsyncThunk(
  'season/getPlayerTicketList',
  async (playerDetail, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('playerTicketList', playerDetail);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPlayerTicketListByDrawdown = createAsyncThunk(
  'season/getPlayerTicketListByDrawdown',
  async (playerDetail, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('playerTicketList', playerDetail);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const purchaseTicket = createAsyncThunk(
  'season/purchaseTicket',
  async (ticketData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post('purchaseTicket', ticketData);
      if (data && data.new_player_balance !== undefined) {
        dispatch(setPlayerBalance(data.new_player_balance));
      }
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkTicketNumbers = createAsyncThunk(
  'season/checkTicketNumbers',
  async (ticketData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('checkTicketNumbers', ticketData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
