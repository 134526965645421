import { createSlice } from '@reduxjs/toolkit';
import {
  fetchActiveDrawdown,
  fetchContactPlatform,
  fetchHomeSeasonList,
  fetchLastWinners,
  fetchPagesContent,
  fetchSocialProof,
  getUSDPrice,
} from '../actions/common';

const initialState = {
  isLoading: false,
  contentList: [],
  contentBanner: null,
  contactPlatformList: [],
  discordLink: 'Coming Soon',
  telegramLink: 'Coming Soon',
  fetchedContact: false,
  aboutContentType: 'About Us',
  socialProof: [],
  lastWinners: null,
  activeDrawdown: null,
  playableSeasons: null,
  seasonList: [],
  usdPrice: 0,
};

export const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setFAQIsOpen: (state, action) => {
      state.contentList[action.payload].is_open =
        !state.contentList[action.payload].is_open;
    },
    setAboutContent: (state, action) => {
      state.aboutContentType = action.payload;
    },
  },
  extraReducers: {
    [fetchPagesContent.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPagesContent.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const contents = payload.pages_content_list;
      if (contents.length) {
        if (contents[0].content_type === 'FAQ') {
          contents.map((content) => (content.is_open = false));
        }
        contents.map(
          (cnt) => (cnt.content = cnt.content.replaceAll('<p></p>', '</br>'))
        );
        state.contentBanner = contents[0].banner;
        state.contentList = contents;
      } else {
        state.contentBanner = null;
        state.contentList = [];
      }
    },
    [fetchPagesContent.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchActiveDrawdown.fulfilled]: (state, { payload }) => {
      if (payload.active_drawdown && payload.active_drawdown.lottery_season) {
        state.activeDrawdown = payload.active_drawdown;
      }
    },
    [fetchSocialProof.fulfilled]: (state, { payload }) => {
      state.socialProof = payload.social_proof;
    },
    [fetchLastWinners.fulfilled]: (state, { payload }) => {
      state.lastWinners = payload.last_lottery_winners;
    },
    [fetchHomeSeasonList.fulfilled]: (state, { payload }) => {
      if (payload.lottery_season_list && payload.lottery_season_list.length) {
        state.seasonList =
          payload.lottery_season_list.length < 4
            ? payload.lottery_season_list
            : payload.lottery_season_list.slice(0, 4);
      }
    },
    [fetchContactPlatform.fulfilled]: (state, { payload }) => {
      state.fetchedContact = true;
      const { contact_platform_list } = payload;
      state.contactPlatformList = contact_platform_list;
      if (contact_platform_list && contact_platform_list.length) {
        const discord = contact_platform_list.filter(
            (contact) => contact.name.toLowerCase() === 'discord'
          ),
          telegram = contact_platform_list.filter(
            (contact) => contact.name.toLowerCase() === 'telegram'
          );

        if (discord.length) state.discordLink = discord[0].link;
        if (telegram.length) state.telegramLink = telegram[0].link;
      }
    },
    [fetchContactPlatform.rejected]: (state, { payload }) => {
      state.fetchedContact = true;
    },
    [getUSDPrice.fulfilled]: (state, { payload }) => {
      state.usdPrice = payload.usd_price;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFAQIsOpen, setAboutContent } = common.actions;

export default common.reducer;
