import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
  return (
    <Backdrop
      sx={{ color: '#27AE60', zIndex: 15000 }}
      open={true}
      className="flex flex-col"
    >
      <CircularProgress color="inherit" />
      <p className="text-lg font-poppins-semibold mt-[10px]">Loading...</p>
    </Backdrop>
  );
};

export default Loading;
