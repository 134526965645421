import { useSelector } from 'react-redux';
import { setPrizeCurrency, thousandSeparator } from '../../utils/math';
import CoinLogo from '../../assets/imgs/coin.png';

const SeasonTitle = ({ classes = '', isBanner = false, winAnnoun = false }) => {
  const selectedSeasonInfo = useSelector(
    (state) => state.season.selectedSeasonInfo
  );

  return (
    <div
      className={`flex flex-col px-[16px] mt-[24px] gap-[4px] mb-[16px] ${classes} ${
        isBanner
          ? 'absolute top-[8vh] left-[21vh] bg-black-100 bg-opacity-50 w-[40%] hidden sm:flex py-[8px] rounded-md'
          : 'sm:hidden'
      } ${winAnnoun && '!mt-0'}`}
    >
      <h3
        className={`font-poppins-medium ${
          isBanner ? 'text-[32px] text-white' : 'text-[24px]'
        }`}
      >
        {selectedSeasonInfo.lottery_season.name}
      </h3>
      <div className="flex flex-col">
        <p
          className={`${
            isBanner && 'text-white'
          } text-sm font-poppins-semibold`}
        >
          Total Prize
        </p>
        <span className="flex flex-row items-center">
          {/* <img alt="coin-logo" src={CoinLogo} className="w-[34px] h-[34px]" /> */}
          <h3
            className={`text-[24px] ${
              isBanner ? 'text-yellow' : 'text-dark-green'
            } text-dark-green font-poppins-semibold ml-[4px]`}
          >
            {setPrizeCurrency(selectedSeasonInfo)}{' '}
            {thousandSeparator(
              selectedSeasonInfo.active_drawdown.winning_pot_budget
            )}
          </h3>
        </span>
      </div>
    </div>
  );
};

export default SeasonTitle;
