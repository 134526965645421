import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPassOTP } from '../../utils/validationForm';
import Input from '../Input/Input';
import ActionButton from '../Button/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordStep1, forgotPasswordStep2 } from '../../actions/auth';
import { setForgotPassPayload } from '../../slices/authSlice';
import getToastMessage from '../../utils/toastMessage';
import { formatTimeFromSeconds } from '../../utils/date';

const ForgotPasswordOTP = ({ closeModal }) => {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(forgotPassOTP),
  });
  const forgotPassPayload = useSelector(
    (state) => state.auth.forgotPassPayload
  );
  const dispatch = useDispatch();
  const [countdown, setCountDown] = useState(300);

  useEffect(() => {
    if (countdown) {
      const interval = setInterval(() => {
        setCountDown((prevState) => (prevState -= 1));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countdown]);

  const onSubmit = async (data) => {
    var payload = { ...forgotPassPayload, ...data };
    const done = await dispatch(forgotPasswordStep2(payload));
    if (done) {
      const { variant } = getToastMessage(done);
      if (variant === 'success') {
        dispatch(setForgotPassPayload(payload));
        closeModal('change_password');
      }
    }
  };

  return (
    <Modal closeModal={closeModal} title="OTP for Forgot Password">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register}
          errors={formState.errors}
          title="OTP Number"
          name="otp"
          placeholder="Input OTP sent to your email"
        />
        <p className="text-lg mb-[16px]">
          Don't received?{' '}
          <span
            className={`text-blue cursor-pointer font-poppins-bold ${
              !countdown && 'underline'
            }`}
            onClick={() => {
              if (!countdown) {
                dispatch(forgotPasswordStep1(forgotPassPayload)).then(() =>
                  setCountDown(300)
                );
              }
            }}
          >
            {!countdown
              ? 'Send OTP'
              : `Try again in ${formatTimeFromSeconds(countdown)}`}
          </span>
        </p>
        <ActionButton type="submit" title="Reset Password" />
      </form>
    </Modal>
  );
};

export default ForgotPasswordOTP;
