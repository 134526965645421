import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { fetchPlayerInfo } from '../actions/auth';

export const useIsLoggedIn = () => {
  const { userInfo, isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const token = localStorage.getItem('USER_KEY');

  if (userInfo && userInfo.name && token) return true;
  else if ((!userInfo || !userInfo.name) && token) {
    if (!isLoading) dispatch(fetchPlayerInfo());
    return true;
  }
  return false;
};

export const useIsSuperAdmin = () => {
  const user = useSelector((state) => state.auth.userInfo);
  return user.role === 'Super Admin' ? true : false;
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  localStorage.removeItem('USER_KEY');
  dispatch(setUserInfo(null));
  navigate('/login');
};
