const InputSearch = ({ title, placeholder, classes, onSearch }) => {
  return (
    <div className="flex flex-col mb-[24px] w-full">
      <label className="text-sm ">{title}</label>
      <input
        placeholder={placeholder}
        onKeyDown={onSearch}
        className={`bg-white w-full sm:w-1/3 p-[8px] border border-black-100 rounded-md text-sm outline-none ${classes}`}
      />
    </div>
  );
};

export default InputSearch;
