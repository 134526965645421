import { useSelector } from 'react-redux';
import { useIsLoggedIn } from '../../hooks/useAuth';
import ActionButton from '../Button/ActionButton';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import Wallet from '../Modal/Wallet';
import ReferFriends from '../Modal/ReferFriends';

const HowToPlay = ({ isHome = false }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [modalSection, setModalSection] = useState('');
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  return (
    <>
      {modalSection === 'wallet' ? (
        <Wallet closeModal={setModalSection} />
      ) : modalSection === 'refer' ? (
        <ReferFriends closeModal={setModalSection} />
      ) : null}
      <div className="flex flex-col py-[46px] px-[16px] items-center gap-[24px] sm:gap-[36px]">
        {!isHome ? (
          <>
            <h2 className="text-center text-[36px] font-poppins-bold">
              How to Play?
            </h2>
            <hr className="h-[2px] w-[156px] bg-black-100" />
            <h4 className="text-lg text-center">
              Get started today with our simple and easy lottery playing
              process!
            </h4>
          </>
        ) : null}
        <div
          className={`flex flex-col sm:flex-row sm:justify-between gap-[24px] w-full ${
            isHome && '!flex-row overflow-auto !gap-0 sm:!justify-center'
          }`}
        >
          <div
            className={`flex flex-row items-center justify-between gap-[16px] sm:min-w-[30%] ${
              isHome &&
              'min-w-[328px] w-[328px] border border-black-100 py-[8px] px-[36px]'
            }`}
          >
            <h2 className="text-[96px] min-w-[56px] font-poppins-bold">1</h2>
            <div
              className={`flex flex-col border-l border-l-black-100 px-[16px] gap-[16px] ${
                isHome && 'border-l-0'
              }`}
            >
              <h3 className="text-lg font-poppins-bold">
                {isLoggedIn ? "YOU'RE REGISTERED" : 'REGISTER'}
              </h3>
              <span className="h-[98px] font-poppins-semibold">
                {isLoggedIn
                  ? 'Earn CKU! Just share your referral link with friends and help them register. When they buy tickets, you earn.'
                  : "Fill in some simple details and you'll be ready to go."}
              </span>
              <ActionButton
                title={isLoggedIn ? 'Easy Refer Friend' : 'Sign In/Up'}
                classes="!bg-black-100 text-white uppercase"
                onClick={() =>
                  isLoggedIn ? setModalSection('refer') : navigate('/login')
                }
              />
            </div>
          </div>
          <div
            className={`flex flex-row items-center justify-between gap-[16px] sm:min-w-[30%] ${
              isHome &&
              'min-w-[328px] w-[328px] border border-black-100 py-[8px] px-[36px]'
            }`}
          >
            <h2 className="text-[96px] min-w-[56px] font-poppins-bold">2</h2>
            <div
              className={`flex flex-col border-l border-l-black-100 px-[16px] gap-[16px] ${
                isHome && 'border-l-0'
              }`}
            >
              <h3 className="text-lg font-poppins-bold">
                GET LUCKY CHIPS (CKU)
              </h3>
              <span className="h-[98px] font-poppins-semibold">
                It's Simple! Buy CKU and transfer it into Luckycat88 to receive
                CKU.
              </span>
              <ActionButton
                title="Get Lucky Chips (CKU)"
                classes="!bg-black-100 text-white uppercase"
                disabled={isLoggedIn ? false : true}
                onClick={() => setModalSection('wallet')}
              />
            </div>
          </div>
          <div
            className={`flex flex-row items-center justify-between gap-[16px] sm:min-w-[30%] ${
              isHome &&
              'min-w-[328px] w-[328px] border border-black-100 py-[8px] px-[36px]'
            }`}
          >
            <h2 className="text-[96px] min-w-[56px] font-poppins-bold">3</h2>
            <div
              className={`flex flex-col border-l border-l-black-100 px-[16px] gap-[16px] ${
                isHome && 'border-l-0'
              }`}
            >
              <h3 className="text-lg font-poppins-bold">PLAY LOTTERY</h3>
              <span className="h-[98px] font-poppins-semibold">
                Buy Tickets and Win! There are multiple prize pots to win, and
                you could be the next Jackpot winner!
              </span>
              <ActionButton
                title="Play"
                classes="!bg-black-100 text-white uppercase"
                disabled={
                  userInfo && userInfo.lucky_coin_balance ? false : true
                }
                onClick={() => {
                  location.pathname.includes('play')
                    ? setSearchParams({ section: 'drawdown' })
                    : navigate('/play');
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToPlay;
