import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../components/Input/Input';
import { sendMessage } from '../utils/validationForm';
import Loading from '../components/Loading';
import ActionButton from '../components/Button/ActionButton';
import LayoutUser from '../components/Layout/LayoutUser';
import ReCAPTCHA from 'react-google-recaptcha';
import { enqueueSnackbar } from 'notistack';
import { sendEmailMessage } from '../actions/auth';
import getToastMessage from '../utils/toastMessage';

const ContactPage = () => {
  const dispatch = useDispatch();
  const recaptcha = useRef();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      resolver: yupResolver(sendMessage),
    });

  const onSubmit = async (data) => {
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      enqueueSnackbar(
        'Please fill in all fields and complete the captcha before submitting this form',
        { variant: 'warning' }
      );
    } else {
      const payload = JSON.parse(JSON.stringify(data));
      payload[`captcha_response`] = captchaValue;

      const done = await dispatch(sendEmailMessage(payload));
      if (done) {
        const { message, variant } = getToastMessage(done);
        if (variant === 'success') {
          recaptcha.current.reset();
          reset();
          setValue('message', '');
          enqueueSnackbar(message, { variant });
        }
      }
    }
  };

  return (
    <LayoutUser>
      {isLoading ? <Loading /> : null}
      <div className="flex flex-col w-full h-full sm:flex-row sm:justify-between">
        <div className="bg-blue w-full h-[166px] sm:w-[55%] sm:h-[100vh]"></div>
        <div className="flex flex-col justify-center items-center w-[80%] m-auto pb-[32px] sm:w-[35%]">
          <h2 className="text-2xl font-poppins-semibold my-[32px]">
            Send us a message
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full mx-auto">
            <Input
              title="Email"
              placeholder="yourmail@mail.com"
              register={register}
              name="email"
              errors={formState.errors}
            />
            <Input
              title="Title/Subject"
              placeholder="Title/Subject"
              register={register}
              name="title"
              errors={formState.errors}
            />
            <div>
              <label className="text-sm">Message</label>
              <Controller
                control={control}
                name="message"
                rules={{ required: true }}
                render={({ field }) => (
                  <textarea
                    {...field}
                    onChange={field.onChange}
                    placeholder="Message"
                    className="p-[8px] bg-white border border-black-100 outline-none text-sm rounded-md w-full min-h-[100px]"
                  />
                )}
              />
            </div>
            <p className="text-sm my-[16px]">
              We’ll respond to your email shortly.
            </p>
            <ReCAPTCHA
              ref={recaptcha}
              sitekey={process.env.REACT_APP_SITE_KEY}
            />
            <ActionButton title="Submit" type="submit" classes="mt-[16px]" />
          </form>
        </div>
      </div>
    </LayoutUser>
  );
};

export default ContactPage;
