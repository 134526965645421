import React, { useState } from 'react';
import SecondaryButton from '../Button/SecondaryButton';
import { Backdrop } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { CURRENT_TIME } from '../../utils/date';
import { getTotalTickets } from '../../utils/math';

const Cart = ({ openBuyModal }) => {
  const [open, setOpen] = useState(false);
  const {
    selectedTickets,
    selectedSeasonInfo,
    playerTicketList,
    tempGeneratedTickets,
  } = useSelector((state) => state.season);

  return (
    <div className="sm:hidden">
      <Backdrop
        open={open && selectedTickets.length ? true : false}
        onClick={() => setOpen(false)}
        sx={{ zIndex: 25 }}
      />
      <div
        className={`bg-blue transition-all duration-500 fixed z-[30] bottom-[64px] w-full border border-black-100 rounded-tl-[24px] rounded-tr-[24px] ${
          open && (selectedTickets.length || playerTicketList.length)
            ? 'h-[calc(55vh+10px)]'
            : 'h-[130px]'
        }`}
      >
        <div className="py-[16px] px-[10px]">
          <div
            className="flex flex-row justify-between items-end px-[5px] pb-[10px]"
            onClick={() => setOpen(!open)}
          >
            <h3 className="text-lg font-poppins-semibold">Get Tickets</h3>
            <p className="font-poppins-semibold text-sm">
              {getTotalTickets(tempGeneratedTickets)} tickets
            </p>
          </div>
          {open && selectedTickets.length && tempGeneratedTickets.length ? (
            <div className="max-h-[calc(55vh-110px)] overflow-auto flex flex-col gap-[20px]">
              {tempGeneratedTickets.map((ticket, idx) => (
                <React.Fragment key={`ticket ${idx}`}>
                  {ticket.ticket_list.map((dTicket, dIdx) => (
                    <div
                      key={`d-${idx}-${dIdx}`}
                      className="w-[98%] last:mb-[12px] flex flex-col px-[12px] pt-[8px] pb-[20px] mb-[4px] border border-black-100 shadow-thick rounded-lg bg-light-yellow"
                    >
                      <p className="text-sm font-poppins-semibold text-right mb-[4px]">
                        {ticket.drawdown.name} #{dIdx + 1}
                      </p>
                      <div className="flex flex-row gap-[8px] justify-center">
                        {dTicket.map((num, id) => (
                          <span
                            key={`t-${idx} ${id}`}
                            className="text-sm w-[26px] h-[26px] bg-black-100 rounded-full flex items-center justify-center font-poppins-bold text-white"
                          >
                            {num}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          ) : null}
          <div className="py-[8px] mt-auto">
            <SecondaryButton
              title={
                tempGeneratedTickets && tempGeneratedTickets.length
                  ? 'Edit Tickets'
                  : 'Add New Tickets'
              }
              type="button"
              classes="w-full"
              onClick={() => {
                if (
                  !selectedSeasonInfo.active_drawdown.purchase_freeze_time ||
                  (selectedSeasonInfo.active_drawdown.purchase_freeze_time &&
                    moment(CURRENT_TIME).isBefore(
                      selectedSeasonInfo.active_drawdown.purchase_freeze_time
                    ))
                ) {
                  openBuyModal(true);
                } else if (
                  moment(CURRENT_TIME).isBefore(selectedSeasonInfo.startDate)
                ) {
                  enqueueSnackbar('The season has not started yet', {
                    variant: 'warning',
                  });
                } else {
                  enqueueSnackbar(
                    'You can no longer purchase tickets for this drawdown',
                    { variant: 'warning' }
                  );
                }
              }}
            />
          </div>
          <span className="absolute top-[8px] h-[2px] rounded-lg w-[20%] left-1/2 transform -translate-x-1/2 bg-black-100"></span>
        </div>
      </div>
    </div>
  );
};

export default Cart;
