import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logout from '../components/Modal/Logout';
import ReferFriends from '../components/Modal/ReferFriends';
import Wallet from '../components/Modal/Wallet';
import LayoutUser from '../components/Layout/LayoutUser';
import ExchangeWallet from '../components/Modal/ExchangeWallet';

const ProfilePage = () => {
  const sections = useSelector((state) => state.player.profileSections);
  const [modalSection, setModalSection] = useState('');
  const navigate = useNavigate();
  return (
    <LayoutUser showTabMenu={true}>
      {modalSection === 'logout' ? (
        <Logout closeModal={setModalSection} />
      ) : modalSection === 'refer_friend' ? (
        <ReferFriends closeModal={setModalSection} />
      ) : modalSection === 'wallet' ? (
        <Wallet closeModal={setModalSection} />
      ) : modalSection === 'exchange_wallet' ? (
        <ExchangeWallet closeModal={setModalSection} />
      ) : null}
      <main className="flex flex-col sm:flex-row sm:items-center">
        <div className="bg-blue w-full h-[166px] sm:w-[55%] sm:h-[100vh]"></div>
        <div className="px-[16px] py-[45px] sm:w-[40%] flex items-center justify-center">
          <div className="w-full sm:w-[70%] h-fit border border-black-100 bg-light-yellow">
            {sections.map((section, idx) => (
              <div
                key={`${section.name}-${idx}`}
                className="flex flex-row justify-between items-center p-[14px] cursor-pointer font-poppins-semibold border-b border-b-black-100 last:border-b-0"
                onClick={() => {
                  if (section.path) navigate(section.path);
                  else if (section.modal) setModalSection(section.modal);
                }}
              >
                <div className="flex flex-row items-center">
                  <ion-icon
                    name={section.icon}
                    style={{ width: '20px', height: '20px' }}
                  ></ion-icon>
                  <p className="text-lg ml-[10px]">{section.name}</p>
                </div>
                <button className="flex" type="button">
                  <ion-icon
                    name={section.path ? 'arrow-forward-circle' : 'open'}
                    style={{ color: '#2F80ED', width: '20px', height: '20px' }}
                  ></ion-icon>
                </button>
              </div>
            ))}
          </div>
        </div>
      </main>
    </LayoutUser>
  );
};

export default ProfilePage;
