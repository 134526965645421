import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobileNavOpen: false,
  selectedMenu: 'Home',
  selectedTab: 'Home',
  tabMenus: [
    { name: 'Home', iconName: 'home', path: '/' },
    { name: 'Play', iconName: 'bowling-ball', path: '/play' },
    { name: 'History', iconName: 'ticket', path: '/history' },
    { name: 'Profile', iconName: 'person-circle', path: '/profile' },
  ],
  menus: [
    { name: 'Home', path: '/' },
    {
      name: 'Play',
      path: '/play',
    },
    {
      name: 'About',
      path: '/about',
    },
    {
      name: 'FAQ',
      path: '/faq',
    },
    {
      name: 'Contact',
      path: '/contact',
    },
  ],
};

export const navbar = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
    setSelectedTabMenu: (state, action) => {
      state.selectedTab = action.payload;
    },
    setIsMobileNavOpen: (state, action) => {
      state.isMobileNavOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedMenu, setIsMobileNavOpen, setSelectedTabMenu } =
  navbar.actions;

export default navbar.reducer;
