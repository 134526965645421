import ReactPaginate from 'react-paginate';

const Pagination = ({ pageData, totalPages, onChange }) => {
  return (
    <>
      {totalPages >= 2 ? (
        <ReactPaginate
          className="flex flex-row justify-end w-full mt-[32px] pr-[16px]"
          pageClassName="h-[24px] w-[24px] pt-[2px] leading-[20px] text-center"
          activeClassName="bg-black-100 text-white"
          nextLabel={
            <ion-icon
              name="chevron-forward-outline"
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '8px',
                opacity: pageData.page === totalPages - 1 ? '0.4' : '1',
              }}
            ></ion-icon>
          }
          previousLabel={
            <ion-icon
              name="chevron-back-outline"
              style={{
                width: '24px',
                height: '24px',
                marginRight: '8px',
                opacity: pageData.page === 0 ? '0.4' : '1',
              }}
            ></ion-icon>
          }
          pageCount={totalPages}
          pageRangeDisplayed={5}
          breakLabel="..."
          forcePage={pageData.page}
          renderOnZeroPageCount={null}
          onPageChange={onChange}
        />
      ) : null}
    </>
  );
};

export default Pagination;
