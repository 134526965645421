import { createSlice } from '@reduxjs/toolkit';
import {
  checkReceiverName,
  checkTransactionId,
  connectNewWallet,
  editWalletInfo,
  fetchWalletList,
  sendConnectWalletOTP,
  sendLuckyCoinInternal,
  sendTransferOTP,
  sendWithdrawOTP,
  withdrawLuckyCoin,
} from '../actions/wallet';

const initialState = {
  isLoading: false,
  sendLCData: null,
  withdrawLCData: null,
  walletList: [],
  activeWallet: null,
  refetchWalletList: false,
};

export const wallet = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setLCData: (state, action) => {
      const { type, data } = action.payload;
      if (type === 'send') state.sendLCData = data;
      if (type === 'withdraw') state.withdrawLCData = data;
      if (type === 'reset') {
        state.sendLCData = null;
        state.withdrawLCData = null;
      }
    },
    setRefetchWalletList: (state, action) => {
      state.refetchWalletList = action.payload;
    },
    setWallet: (state, action) => {
      state.walletList = action.payload;
      state.activeWallet = action.payload.filter(
        (wallet) => wallet.is_active
      )[0];
    },
    resetData: (state, action) => {
      state.withdrawLCData = null;
      state.sendLCData = null;
      state.activeWallet = null;
      state.walletList = [];
    },
  },
  extraReducers: {
    [checkTransactionId.pending]: (state) => {
      state.isLoading = true;
    },
    [checkTransactionId.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [checkTransactionId.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [withdrawLuckyCoin.pending]: (state) => {
      state.isLoading = true;
    },
    [withdrawLuckyCoin.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [withdrawLuckyCoin.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendLuckyCoinInternal.pending]: (state) => {
      state.isLoading = true;
    },
    [sendLuckyCoinInternal.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendLuckyCoinInternal.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendTransferOTP.pending]: (state) => {
      state.isLoading = true;
    },
    [sendTransferOTP.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendTransferOTP.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendWithdrawOTP.pending]: (state) => {
      state.isLoading = true;
    },
    [sendWithdrawOTP.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendWithdrawOTP.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendConnectWalletOTP.pending]: (state) => {
      state.isLoading = true;
    },
    [sendConnectWalletOTP.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendConnectWalletOTP.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [checkReceiverName.pending]: (state) => {
      state.isLoading = true;
    },
    [checkReceiverName.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [checkReceiverName.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [connectNewWallet.pending]: (state) => {
      state.isLoading = true;
    },
    [connectNewWallet.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.new_player_wallet_data) {
        state.walletList = payload.new_player_wallet_data;
        state.activeWallet = payload.new_player_wallet_data.filter(
          (wallet) => wallet.is_active
        )[0];
      }
    },
    [connectNewWallet.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [editWalletInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [editWalletInfo.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.new_player_wallet_data) {
        state.walletList = payload.new_player_wallet_data;
        state.activeWallet = payload.new_player_wallet_data.filter(
          (wallet) => wallet.is_active
        )[0];
      }
    },
    [editWalletInfo.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchWalletList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchWalletList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.refetchWalletList = false;
      if (payload.external_wallet_list && payload.external_wallet_list.length) {
        state.walletList = payload.external_wallet_list;
        state.activeWallet = payload.external_wallet_list.filter(
          (wallet) => wallet.is_active
        )[0];
      }
    },
    [fetchWalletList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLCData, setRefetchWalletList, resetData, setWallet } =
  wallet.actions;

export default wallet.reducer;
