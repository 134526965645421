import { useEffect, useState } from 'react';
import moment from 'moment';
import { calculateTimeDifference, CURRENT_TIME } from '../../utils/date';

const CountDown = ({ drawDate, startDate }) => {
  const dateFields = ['days', 'hours', 'minutes', 'seconds'];
  const initialCount = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  const [countdownDate, setCountdownDate] = useState(initialCount);

  useEffect(() => {
    var interval = setInterval(() => {
      if (moment(CURRENT_TIME).isBefore(startDate)) {
        if (calculateTimeDifference(startDate)) {
          setCountdownDate(calculateTimeDifference(startDate));
        } else {
          clearInterval();
          setCountdownDate(initialCount);
        }
      } else if (calculateTimeDifference(drawDate)) {
        if (calculateTimeDifference(drawDate)) {
          setCountdownDate(calculateTimeDifference(drawDate));
        } else {
          clearInterval();
          setCountdownDate(initialCount);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawDate, startDate]);

  return (
    <>
      {countdownDate ? (
        <div className="flex flex-row justify-between items-center w-full sm:text-[24px]">
          {dateFields.map((field) => (
            <div className="flex flex-col px-[14px] items-center" key={field}>
              <span className="bg-white-f py-[4px] mb-[4px] sm:mb-[16px] flex items-center justify-center font-poppins-semibold border border-black-100 rounded-lg w-[37px] h-[37px] sm:w-[52px] sm:h-[52px]">
                {countdownDate[`${field}`]}
              </span>
              <p className="text-sm capitalize font-poppins-semibold sm:text-[24px]">
                {field}
              </p>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default CountDown;
