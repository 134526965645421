import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../components/Input/Input';
import { loginUser } from '../utils/validationForm';
import { useIsLoggedIn } from '../hooks/useAuth';
import Loading from '../components/Loading';
import ActionButton from '../components/Button/ActionButton';
import SecondaryButton from '../components/Button/SecondaryButton';
import ForgotPasswordEmail from '../components/Modal/ForgotPasswordEmail';
import ForgotPasswordOTP from '../components/Modal/ForgotPasswordOTP';
import ChangePassword from '../components/Modal/ChangePassword';
import InputPassword from '../components/Input/InputPassword';
import { playerLogin, registerPlayerResendOTP } from '../actions/auth';
import LayoutUser from '../components/Layout/LayoutUser';
import RegisterOTP from '../components/Modal/RegisterOTP';
import getToastMessage from '../utils/toastMessage';
import { enqueueSnackbar } from 'notistack';

const LoginPage = () => {
  const { isLoading, userInfo } = useSelector((state) => state.auth);
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState, getValues } = useForm({
    resolver: yupResolver(loginUser),
  });
  const [modalSection, setModalSection] = useState('');

  const onSubmit = async (data) => {
    const done = await dispatch(playerLogin(data));
    if (done) {
      const { payload } = done;
      if (payload && payload.status === 'Registration Pending') {
        const sendOTP = await dispatch(
          registerPlayerResendOTP({ email: data.email })
        );
        if (sendOTP) {
          const { message, variant } = getToastMessage(sendOTP);
          if (variant === 'success') {
            setModalSection('otp_register');
            enqueueSnackbar(message, { variant });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (userInfo || isLoggedIn) {
      document.activeElement.blur();
      reset();
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isLoggedIn]);

  return (
    <LayoutUser>
      {isLoading ? <Loading /> : null}
      {modalSection === 'forgot_pass_email' ? (
        <ForgotPasswordEmail closeModal={setModalSection} />
      ) : modalSection === 'forgot_pass_otp' ? (
        <ForgotPasswordOTP closeModal={setModalSection} />
      ) : modalSection === 'change_password' ? (
        <ChangePassword closeModal={setModalSection} />
      ) : modalSection === 'otp_register' ? (
        <RegisterOTP email={getValues('email')} closeModal={setModalSection} />
      ) : null}
      <div className="flex flex-col w-full h-full sm:flex-row sm:justify-between">
        <div className="bg-blue w-full h-[166px] sm:w-[55%] sm:h-[100vh]"></div>
        <div className="flex flex-col justify-center items-center w-[80%] m-auto pb-[32px] sm:w-[35%]">
          <h2 className="text-lg font-poppins-semibold my-[32px]">Login</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full mx-auto">
            <Input
              title="Email"
              placeholder="yourmail@mail.com"
              register={register}
              name="email"
              errors={formState.errors}
            />
            <InputPassword
              title="Password"
              placeholder="Password"
              name="password"
              register={register}
              errors={formState.errors}
            />
            <h3
              className="text-lg font-poppins-bold text-black-button underline my-[16px] cursor-pointer"
              onClick={() => setModalSection('forgot_pass_email')}
            >
              Forgot Password?
            </h3>
            <ActionButton title="Login" type="submit" />
          </form>
          <div className="flex flex-col w-full mt-[28px] mb-[24px] relative">
            <hr className="h-[2px] bg-black-60" />
            <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white font-poppins-semibold px-[10px] text-lg">
              or
            </p>
          </div>
          <SecondaryButton
            title="Easy Register"
            onClick={() => navigate('/signup')}
          />
        </div>
      </div>
    </LayoutUser>
  );
};

export default LoginPage;
